import { Outlet } from "react-router-dom"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import Navbar from "./Navbar"
import Footer from "./Footer"

export function MainLayout() {
  return (
    <Container>
      <Navbar layout="main" />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{ mt: "13vh" }}
      >
        <Outlet />
      </Grid>
      <Footer />
    </Container>
  )
}
export default MainLayout
