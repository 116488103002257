import * as PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"

import useRoutes from "../../../helpers/hooks/useRoutes"
import { Popup } from "../../styled/map"
import GroupsChips from "../../deviceGroups/GroupsChips"
import CustomLink from "../../common/CustomLink"

export default function GeoPopup({ device }) {
  const { groups } = device
  const { baseOrgURL } = useRoutes()
  const { t } = useTranslation()

  return (
    <div data-testid="geo-popup">
      <Popup>
        <Stack spacing={0.5}>
          <Typography component="h6" variant="h6">
            ID: {device.id}
          </Typography>
          <Typography component="h6" variant="body2" sx={{ fontWeight: 600 }}>
            {device.name}
          </Typography>
          {device.type && (
            <Typography component="h6" variant="body2">
              <Typography component="span" variant="body2" fontWeight={600}>
                {t("device_information.TYPE")}:
              </Typography>{" "}
              {device.type}
            </Typography>
          )}
          {groups && (
            <>
              <Typography component="span" variant="body2" fontWeight={600}>
                {t("device_groups.GROUPS")}:
              </Typography>{" "}
              <GroupsChips groups={groups} />
            </>
          )}
        </Stack>
        {device.name && (
          <CustomLink
            href={`${baseOrgURL}/devices/${device.id}`}
            bold
            styles={{ textAlign: "right", margin: 0 }}
          >
            {t("generic.GO_TO_DEVICE")}
          </CustomLink>
        )}
      </Popup>
    </div>
  )
}

GeoPopup.propTypes = {
  device: PropTypes.object,
}
