import type { FC, PropsWithChildren } from "react"
import { useMemo, useReducer, useState } from "react"
import fp from "lodash/fp"
import { Responsive, WidthProvider } from "react-grid-layout"

import { GRID_LAYOUT_GAP } from "helpers/utils/constants"
import { cellStateReducer, layoutReducer } from "helpers/reducers/dashboardReducers"
import type { IPlotDef } from "types/dashboard.types"
import { getLayout } from "helpers/utils/dashboards"

import Message from "../common/Message"
import DeviceTelemetryPlot from "../plots/TelemetryPlot"
import DashboardContext from "./DeviceDashboard/DashboardContext"

const GridLayout = WidthProvider(Responsive)

interface DashboardProps {
  deviceId: number | string
  config: {
    type: string
    plotDefs: IPlotDef[]
  }
}

const Dashboard: FC<PropsWithChildren<DashboardProps>> = ({
  deviceId,
  config,
  children,
}) => {
  const initialLayout = getLayout(config.type)
  //TODO: Once we have more layouts for the different media queries, we'll have to take that into account for the initial layout
  const [plotLayout, dispatchPlotLayout] = useReducer(layoutReducer, initialLayout)
  const layout = useMemo(() => fp.values(plotLayout), [plotLayout])
  const [plotState, dispatchPlotState] = useReducer(cellStateReducer, {})
  const lgRowHeight = 130
  const [rowHeight, setRowHeight] = useState(lgRowHeight)

  const plotsAreFetching = Object.values(plotState).some((value) => value)
  const isData = Object.values(layout).some((value) => value.h !== 0)

  return (
    <>
      <DashboardContext.Provider
        value={{
          config,
          rowHeight,
          id: deviceId,
          deviceId,
          cellsAreFetching: plotsAreFetching,
          plotsAreFetching,
          dispatchPlotLayout,
          dispatchPlotState,
        }}
      >
        {children}
        <GridLayout
          style={{
            position: "relative",
            marginRight: `-${GRID_LAYOUT_GAP}px`,
            marginLeft: `-${GRID_LAYOUT_GAP}px`,
          }}
          layouts={{ lg: layout }}
          breakpoints={{
            lg: 1000,
            md: 800,
            sm: 650,
          }}
          cols={{ lg: 12, md: 6, sm: 3 }}
          margin={[GRID_LAYOUT_GAP, GRID_LAYOUT_GAP]}
          rowHeight={rowHeight}
          draggableHandle=".dragIcon"
          onBreakpointChange={(breakpoint: string) =>
            breakpoint === "sm" ? setRowHeight(120) : setRowHeight(lgRowHeight)
          }
        >
          {config.plotDefs.map(({ view, draggable, reqConfig }) => {
            return (
              <div key={view.nameKey}>
                <DeviceTelemetryPlot
                  view={view}
                  reqConfig={reqConfig}
                  draggable={draggable}
                />
              </div>
            )
          })}
        </GridLayout>
        {!plotsAreFetching && !isData && (
          <Message messageKey={"generic.NO_DATA_IN_TIME_WINDOW"} />
        )}
      </DashboardContext.Provider>
    </>
  )
}

export default Dashboard
