import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { stringify as qsStringify } from "query-string"

import { LogoContainer } from "widgets/styled/containers"
import { useLoginMutation } from "../../features/api"
import { setCredentials } from "../../features/store/authSlice"
import { DEFAULT_TARGET, IMAGOTYPE } from "../../helpers/utils/constants"
import ControlledInput from "../../widgets/common/ControlledInput"
import LoadingButton from "../../widgets/common/LoadingButton"
import ControlledPasswordInput from "../../widgets/common/ControlledPasswordInput"
import CustomLink from "../../widgets/common/CustomLink"
import type { LocationState } from "../../types/locationState.types"

function Login() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location?.state
    ? (location.state as LocationState)
    : ({} as LocationState)
  const methods = useForm({
    mode: "all",
    defaultValues: { username: "", password: "" },
  })
  const {
    handleSubmit,
    getValues,
    formState: { isSubmitted },
  } = methods

  const [login, { isLoading }] = useLoginMutation()

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onSubmit = async () => {
    const account = {
      username: getValues("username"),
      password: getValues("password"),
    }
    return login(account)
      .unwrap()
      .then((user) => {
        dispatch(
          setCredentials({
            user: account.username,
            token: user.access_token,
            code: user.code,
            globalRole: user.role,
          }),
        )
        const target = locationState.redirectedFrom ?? DEFAULT_TARGET
        navigate(target, { state: { redirectedFrom: location.pathname } })
      })
      .catch((error) => {
        /* TODO: The error messages should be the ones sent by the API.
         * At the moment, it is sending some very long messages that are not user friendly.
         *
         * It would be a good practice to always use the backend error messages. If the backend
         * does not send them in the user's language, we could add a messageKey in the response,
         * that we can use as the translation key. See sentrisense/cloud#1948.
         */
        const body = error.data ?? {}
        if (
          error.status === 401 &&
          (body?.code === "E_WEAK_PASS" || body?.code === "E_EXPIRED_PASS")
        ) {
          const query = qsStringify({ reason: body.code })
          navigate(`/users/${account.username}/modify-password?${query}`)
        }
        const message =
          error.status === 404 || error.status === 401 || !error.data
            ? t("login.EMAIL_PASS_INVALID")
            : error.data.message
        setErrorMessage(message)
      })
  }

  return (
    <Card
      sx={{ maxWidth: 360, minWidth: 280, width: "50%", textAlign: "center" }}
      elevation={4}
    >
      <LogoContainer disableGutters>
        <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
      </LogoContainer>
      <CardContent sx={{ px: "7%" }}>
        <Typography variant="body2" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
          {t("login.SIGN_IN_TO_CONTINUE")}
        </Typography>
        <form name="formLogin" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormProvider {...methods}>
            <Stack spacing={2}>
              <ControlledInput
                size="small"
                type="email"
                placeholder=""
                label="Email"
                variant="outlined"
                name="username"
                rules={{
                  required: t("generic.FIELD_REQUIRED"),
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: t("generic.FIELD_INVALID_EMAIL"),
                  },
                }}
                inputLabelProps={{ shrink: true }}
              />
              <ControlledPasswordInput
                size="small"
                placeholder=""
                label={t("login.PASSWORD")}
                variant="outlined"
                name="password"
                rules={{
                  required: t("generic.FIELD_REQUIRED"),
                }}
                inputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 3 }}>
              <Grid item>
                <CustomLink href="/accounts/recover" underlineHover variant="body2">
                  {t("login.FORGOT_YOUR_PASSWORD")}
                </CustomLink>
              </Grid>
            </Grid>
            {isSubmitted && (
              <Typography color="error" variant="body2" component="p" align="right">
                {errorMessage}
              </Typography>
            )}
            <LoadingButton
              loading={isLoading}
              variant="contained"
              styles={{ width: "100%", mt: 1, mb: 1 }}
            >
              {t("login.LOGIN")}
            </LoadingButton>
          </FormProvider>
        </form>
        {/* Disable register
          <Typography variant="body3" align="center" component="p" sx={{ mt: 1 }}>
            Don&apos;t have an account?&nbsp;
            <Link href="register" variant="body3">
              Register Now
            </Link>
          </Typography>
          */}
      </CardContent>
    </Card>
  )
}

export default Login
