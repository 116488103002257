import { USER_ROLES } from "./constants.ts"

export const ownerCheckByRole = (userRole: string | null): boolean =>
  userRole === USER_ROLES.owner

export const adminCheckByRole = (userRole: string | null): boolean =>
  userRole === USER_ROLES.admin || ownerCheckByRole(userRole)

export const editCheckByRole = (userRole: string | null): boolean =>
  userRole === USER_ROLES.editor || adminCheckByRole(userRole)
