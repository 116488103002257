import type { FC, ReactNode, Ref } from "react"
import { forwardRef } from "react"
import {
  Box,
  Checkbox,
  FormControlLabel,
  type InputLabelProps,
  TextField,
} from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import MenuItem from "@mui/material/MenuItem"
import type { FieldPath, FieldValues, RegisterOptions } from "react-hook-form"
import { useController, useFormContext } from "react-hook-form"
import { get } from "lodash/fp"

export type InputRulesType<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<
  RegisterOptions<TFieldValues, TName>,
  "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
>

interface ControlledInputProps {
  type?: string
  id?: string
  name: string
  label?: string
  placeholder: string
  required?: boolean
  styles?: object
  variant?: "standard" | "filled" | "outlined" | undefined
  selectItems?: { value: string; label: string }[] | null
  defaultChecked?: boolean
  rules?: InputRulesType
  endAdornment?: ReactNode | string
  defaultValue?: string | number | any[] | boolean
  size?: "small" | "medium" | undefined
  ref?: Ref<HTMLInputElement> | null
  disabled?: boolean
  inputProps?: any
  inputLabelProps?: Partial<InputLabelProps>
  "aria-label"?: React.ComponentProps<"input">["aria-label"]
  autoFocus?: boolean
}

const ControlledInput: FC<ControlledInputProps> = forwardRef(
  function ControlledInputInner(
    {
      type = "text",
      name,
      label = "",
      placeholder,
      selectItems,
      defaultValue,
      variant = "standard",
      styles,
      defaultChecked,
      rules,
      endAdornment,
      size,
      disabled,
      inputLabelProps,
      "aria-label": ariaLabel = "input",
      ...props
    }: ControlledInputProps,
    ref: Ref<HTMLInputElement> | null,
  ) {
    const { control } = useFormContext()

    const {
      field: { name: fieldName, onBlur, onChange, value, ref: fieldRef },
      formState: { errors },
    } = useController<Record<string, any>>({
      rules,
      name,
      control,
      ...((typeof defaultValue === "string" || typeof defaultValue === "number") && {
        defaultValue: `${defaultValue}`,
      }),
    })

    const inputErrorObj = get(name, errors)

    return (
      <Box width={"100%"}>
        {type === "checkbox" ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={value !== undefined ? !!value : defaultChecked}
                onChange={onChange}
              />
            }
            label={label}
          />
        ) : (
          <TextField
            {...props}
            {...(typeof defaultValue !== "string" &&
              defaultValue !== undefined &&
              defaultValue !== null &&
              typeof defaultValue !== "number" && { defaultValue })}
            value={value}
            name={fieldName}
            size={size}
            type={type}
            select={type === "select"}
            onBlur={onBlur}
            onChange={onChange}
            label={label}
            placeholder={placeholder}
            inputRef={ref || fieldRef}
            fullWidth
            error={!!inputErrorObj}
            helperText={inputErrorObj?.message || ""}
            required={!!rules?.required}
            variant={variant}
            disabled={disabled}
            InputProps={{
              endAdornment: endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              ),
            }}
            inputProps={{
              "aria-label": ariaLabel,
              role: "textbox",
            }}
            sx={{
              "& .MuiFormHelperText-root": {
                textAlign: "right",
                marginRight: "0px",
              },
              ...(variant === "standard"
                ? {
                    "& .MuiInputBase-root .MuiFormLabel-root": {
                      fontSize: "1rem",
                    },

                    marginBottom: 0,
                    ...styles,
                  }
                : undefined),
            }}
            InputLabelProps={inputLabelProps}
          >
            {type === "select" &&
              selectItems?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      </Box>
    )
  },
)

export default ControlledInput
