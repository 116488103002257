import { useMemo } from "react"
import type { FC, PropsWithChildren } from "react"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import fp from "lodash/fp"

import { MAP_HEIGHT } from "helpers/utils/constants"
import Spinner from "widgets/common/Spinner"
import Plot from "widgets/plots/Plot"
import { SectionCard } from "widgets/styled/containers"
import type { CellViewType, IBaseRecord } from "types/dashboard.types"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContainerProps {}
type ContainerType = FC<PropsWithChildren<ContainerProps>>
const DefaultContainer: FC<PropsWithChildren<ContainerProps>> = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Container maxWidth="xl" disableGutters sx={{ height: MAP_HEIGHT, boxShadow: 3 }}>
        {children}
      </Container>
    </Grid>
  )
}
export const SectionCardContainer: FC<PropsWithChildren<ContainerProps>> = ({
  children,
}) => {
  return (
    <Grid item xs={12}>
      <SectionCard maxWidth="xl" sx={{ height: MAP_HEIGHT, boxShadow: 3, mt: 0, pt: 0 }}>
        {children}
      </SectionCard>
    </Grid>
  )
}

interface MapProps {
  isPending: boolean
  isSuccess: boolean
  data: IBaseRecord[]
  view: CellViewType
  container?: ContainerType
}

const Map: FC<MapProps> = ({
  isPending,
  isSuccess,
  data,
  view,
  container = DefaultContainer,
}) => {
  const shouldRenderMap = useMemo(
    () => isSuccess && fp.some((data) => data.lat && data.lon, data),
    [data, isSuccess],
  )
  const Container = container

  if (isPending) {
    return <Spinner styles={{ height: MAP_HEIGHT }} />
  }
  if (shouldRenderMap) {
    return (
      <Container>
        <Plot view={view} data={data} />
      </Container>
    )
  }
  return <></>
}
export default Map
