import { useTranslation } from "react-i18next"

import Title from "../../widgets/common/Title"

function UserHelp() {
  const { t } = useTranslation()
  return <Title text={t("sidebar.HELP")} />
}

export default UserHelp
