import { lazy, useCallback } from "react"
import type { FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import type { Theme } from "@mui/material/styles"

import { useChangePasswordMutation } from "features/api"
import { selectCurrentUser, updateCode } from "features/store/authSlice"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import ControlledPasswordInput from "../common/ControlledPasswordInput"
import LoadingButton from "../common/LoadingButton"
import PopUp from "../common/PopUp"

const NewPasswordInput = lazy(() =>
  import("../common/NewPasswordInput").then((module) => ({
    default: module.default,
  })),
)

interface IChangePasswordPopUpProps {
  onClose: () => void
  open: boolean
}

interface IChangePasswordForm {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

const ChangePasswordPopUp: FC<IChangePasswordPopUpProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const methods = useForm<IChangePasswordForm>({
    mode: "all",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  })
  const { formState } = methods

  const [changePassword, { isLoading: changePasswordLoading, isSuccess, reset }] =
    useChangePasswordMutation()

  const user = useSelector(selectCurrentUser)

  const onSubmit = (data: IChangePasswordForm) => {
    snackbarMutation({
      mutation: changePassword({
        username: user as string,
        current_password: data.currentPassword,
        new_password: data.newPassword,
      }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.UPDATING_ITEM", { item: t("login.PASSWORD").toLowerCase() }),
      ),
      getSuccessMessage: () =>
        t("success.UPDATING_ITEM", {
          item: t("login.PASSWORD"),
          count: 1,
          context: "female",
        }),
    })
      .then((response) => {
        dispatch(
          updateCode({
            code: response?.code,
          }),
        )
      })
      .then(() => {
        methods.reset()
        reset()
        onClose()
      })
      .catch()
  }

  const handleCancel = useCallback(() => {
    onClose()
    methods.reset()
    reset()
  }, [methods, onClose, reset])

  return (
    <PopUp open={open} onClose={() => {}} disableOverflow>
      {!isSuccess && (
        <Box
          p="20px"
          width={{ sm: "380px" }}
          sx={{
            padding: { xs: "25px", sm: "30px" },
          }}
        >
          <>
            <Stack pb="10px" direction="row" justifyContent="space-between">
              <Typography variant="h4" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
                {t("change_password.CHANGE_PASSWORD")}
              </Typography>
              <CloseIcon
                onClick={handleCancel}
                sx={{ fontSize: "1rem", cursor: "pointer" }}
              />
            </Stack>
            <form autoComplete="new-password" onSubmit={methods.handleSubmit(onSubmit)}>
              <FormProvider {...methods}>
                <Stack>
                  <ControlledPasswordInput
                    size="small"
                    variant="outlined"
                    label={t("change_password.OLD_PASSWORD")}
                    {...methods.register("currentPassword", {
                      required: t("generic.FIELD_REQUIRED"),
                    })}
                  />
                  <NewPasswordInput methods={methods} />
                  <ControlledPasswordInput
                    size="small"
                    variant="outlined"
                    label={t("change_password.NEW_PASSWORD_REPEAT")}
                    {...methods.register("newPasswordConfirmation", {
                      required: t("generic.FIELD_REQUIRED"),
                      validate: {
                        matchesPreviousPassword: (value) => {
                          const { newPassword } = methods.getValues()
                          return newPassword === value || t("register.PASSWORD_MATCH")
                        },
                      },
                    })}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between" pt="20px">
                  <Button
                    size="small"
                    onClick={handleCancel}
                    sx={{
                      color: (theme: Theme) =>
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[300]
                          : theme.palette.grey[600],
                    }}
                  >
                    {t("generic.CANCEL")}
                  </Button>
                  <LoadingButton
                    disabled={!formState.isValid}
                    loading={changePasswordLoading}
                    variant="contained"
                    styles={{ width: "fit-content" }}
                  >
                    {t("generic.UPDATE")}
                  </LoadingButton>
                </Stack>
              </FormProvider>
            </form>
          </>
        </Box>
      )}
    </PopUp>
  )
}

export default ChangePasswordPopUp
