import type { FC } from "react"

import useAlertSettings from "helpers/hooks/useAlertSettings"
import { hasDeviceTriggeredAlerts } from "../../../helpers/utils/alerts"
import type { DeviceWLocationNStatesType } from "../../../types/device.types"
import DeviceMapMarker from "./DeviceMapMarker"

interface IDeviceMapMarkersProps {
  devices: DeviceWLocationNStatesType[]
}

const DeviceMapMarkers: FC<IDeviceMapMarkersProps> = ({ devices }) => {
  const { orgAlertsBySettingId } = useAlertSettings()
  return (
    <>
      {devices?.map((device: DeviceWLocationNStatesType) => {
        const alertState =
          device.settingsStates &&
          hasDeviceTriggeredAlerts(device.settingsStates, orgAlertsBySettingId)
            ? "triggered"
            : "normal"

        return <DeviceMapMarker key={device.id} device={device} alertState={alertState} />
      })}
    </>
  )
}

export default DeviceMapMarkers
