import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { alpha } from "@mui/material/styles"
import Popover from "@mui/material/Popover"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Tooltip from "@mui/material/Tooltip"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"

import { Badge } from "@mui/material"
import {
  invalidateCredentials,
  selectCurrentUser,
  selectCode,
} from "../../features/store/authSlice"
import { WarningPassword } from "../../types/password.types"
import ThemeSwitch from "./ThemeSwitch"
import ChangePasswordPopUp from "./ChangePasswordPopUp"
import PasswordAlert from "./PasswordAlert"

export default function ProfileMenu() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const code = useSelector(selectCode)

  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    dispatch(invalidateCredentials())
    navigate("/accounts/login")
  }

  useEffect(() => {
    const showAlert = code ? Object.keys(WarningPassword).includes(code) : false
    setShowWarningAlert(showAlert)
  }, [code])

  return (
    <>
      <Tooltip title="Profile">
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            mr: 2,
            width: 44,
            height: 44,
            ...(open && {
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            }),
          }}
        >
          <Badge
            color="warning"
            variant="dot"
            overlap="circular"
            invisible={!showWarningAlert}
          >
            <AccountCircleRoundedIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5,
              ml: 0.5,
              overflow: "inherit",
              backgroundColor: (theme) => theme.palette.background.paper,
              backgroundImage: "none",
              width: 240,
            },
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" noWrap>
            {user}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem
          sx={{
            typography: "body2",
            py: 1,
            px: 2.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("generic.DARK_MODE")}
          <ThemeSwitch />
        </MenuItem>
        <MenuItem
          onClick={() => setShowChangePasswordPopup(true)}
          sx={{
            typography: "body2",
            py: 1,
            px: 2.5,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("generic.CHANGE_PASSWORD")}
        </MenuItem>
        {showWarningAlert && <PasswordAlert passwordCode={code} />}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t("generic.LOGOUT")}
          </Button>
        </Box>
      </Popover>

      <ChangePasswordPopUp
        open={showChangePasswordPopup}
        onClose={() => setShowChangePasswordPopup(false)}
      />
    </>
  )
}
