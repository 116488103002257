import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router"

import { useActivateUserMutation } from "features/api"
import { updateCode } from "features/store/authSlice"
import type { ICustomError } from "helpers/errors/errorTypes"
import { IMAGOTYPE } from "helpers/utils/constants"
import { addEndPeriodIfMissing } from "helpers/utils/translations"
import ControlledPasswordInput from "widgets/common/ControlledPasswordInput"
import LoadingButton from "widgets/common/LoadingButton"
import NewPasswordInput from "widgets/common/NewPasswordInput"
import ResultCard from "widgets/common/ResultCard"
import { LogoContainer } from "widgets/styled/containers"

export interface ActivateUserForm {
  newPassword: string
  newPasswordConfirmation: string
}

const ActivateUser = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { username, secret } = useParams()
  const methods = useForm<ActivateUserForm>({
    mode: "all",
    defaultValues: {
      newPassword: "",
      newPasswordConfirmation: "",
    },
  })
  const [activateUser, { isLoading, isSuccess, isError, error }] =
    useActivateUserMutation()

  const onSubmit = (data: ActivateUserForm) => {
    const { newPassword } = data
    activateUser({
      username: String(username),
      secret: String(secret),
      password: newPassword,
    })
      .unwrap()
      .then((response) => {
        dispatch(
          updateCode({
            code: response?.code,
          }),
        )
      })
      .catch(() => {}) // handled by isError
  }

  if (isSuccess) {
    return (
      <ResultCard
        type="success"
        text={t("change_password.MESSAGE_SUCCESS")}
        button={t("generic.RETURN_TO") + t("login.LOGIN_PAGE")}
        onClick={() => navigate("/accounts/login")}
      />
    )
  } else if (isError) {
    const customError = error as ICustomError
    const message =
      t("enterprise.USER_CANNOT_BE_ACTIVATED") +
      ": " +
      addEndPeriodIfMissing(customError.data.message)
    return (
      <ResultCard
        type="error"
        text={message}
        button={t("generic.RETURN_TO") + t("login.LOGIN_PAGE")}
        onClick={() => navigate("/accounts/login")}
      />
    )
  }

  return (
    <>
      <Card
        sx={{ maxWidth: 360, minWidth: 280, width: "50%", textAlign: "center" }}
        elevation={4}
      >
        <LogoContainer disableGutters>
          <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
        </LogoContainer>
        <CardContent sx={{ px: "7%" }}>
          <Typography variant="body2" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
            {t("recover.ENTER_NEW_PASSWORD")}
          </Typography>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <Stack spacing={2}>
                <NewPasswordInput methods={methods} />
                <ControlledPasswordInput
                  aria-label="password confirmation"
                  size="small"
                  placeholder=""
                  label={t("register.RETYPE_PASSWORD")}
                  variant="outlined"
                  {...methods.register("newPasswordConfirmation", {
                    required: t("generic.FIELD_REQUIRED"),
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { newPassword } = methods.getValues()
                        return newPassword === value || t("register.PASSWORD_MATCH")
                      },
                    },
                  })}
                />
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  styles={{ mt: 1, mb: 1 }}
                  aria-label="login"
                >
                  {t("login.LOGIN")}
                </LoadingButton>
              </Stack>
            </FormProvider>
          </form>
        </CardContent>
      </Card>
    </>
  )
}

export default ActivateUser
