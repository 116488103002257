import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import type { StringMap, TOptions } from "i18next"
import { Grid, Typography } from "@mui/material"

import ControlledInput from "widgets/common/ControlledInput"
import SkeletonWrapper from "./SkeletonWrapper"

interface ConditionalInputProps {
  name: string
  type?: string
  labelKey: string
  labelOptions?: string | TOptions<StringMap> | undefined
  defaultValue: string | number | undefined
  isEditing: boolean
  isLoading: boolean
  orientation?: "horizontal" | "vertical"
}

const ConditionalInput = ({
  name,
  type = "text",
  labelKey,
  labelOptions = labelKey,
  defaultValue,
  isEditing,
  isLoading,
  orientation = "horizontal",
}: ConditionalInputProps) => {
  const { t } = useTranslation()

  const alignment = useMemo(
    () => (orientation === "horizontal" ? "center" : "start"),
    [orientation],
  )
  const size = useMemo(() => (orientation === "horizontal" ? 6 : 12), [orientation])
  const columnSpacing = useMemo(
    () => (orientation === "horizontal" ? 4 : 0),
    [orientation],
  )
  const rowSpacing = useMemo(() => (orientation === "vertical" ? 2 : 0), [orientation])

  return (
    <Grid
      container
      columnSpacing={columnSpacing}
      rowSpacing={rowSpacing}
      mb={2}
      alignItems={alignment}
    >
      <Grid item xs={size} sm={size} md={size} lg={size} xl={size}>
        <Typography>{t(labelKey, labelOptions)}</Typography>
      </Grid>
      <Grid item xs={size} mb={isEditing ? -2 : 0}>
        {isEditing ? (
          <ControlledInput
            name={name}
            type={type}
            variant="outlined"
            size="small"
            placeholder={""}
          />
        ) : (
          <SkeletonWrapper isWaiting={isLoading} width={"100vw"}>
            <Typography color={"text.disabled"}>
              {defaultValue === "" ? "-" : defaultValue}
            </Typography>
          </SkeletonWrapper>
        )}
      </Grid>
    </Grid>
  )
}

export default ConditionalInput
