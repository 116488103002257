import { darkTheme } from "styles/themes"
import type { IPlotDef } from "types/dashboard.types"

export const plotDefBase: Partial<IPlotDef> = {
  draggable: true,
  reqConfig: {
    field_pivot: false,
    aggregate_n: 360,
  },
}

// ------ GENERIC VIEWS ---------

export const gaugeView = {
  name: "",
  nameKey: "",
  properties: {
    colors: [
      { id: "0", type: "min", hex: "#BF3D5E", name: "ruby", value: 0 },
      { id: "1", type: "max", hex: "#7CE490", name: "honeydew", value: 100 },
    ],
    length: { isEnforced: true, digits: 0 },
    note: "",
    prefix: "",
    shape: "chronograf-v2",
    showNoteWhenEmpty: false,
    suffix: "",
    tickPrefix: "",
    tickSuffix: "",
    type: "gauge",
  },
}

export const lineView = {
  name: "",
  nameKey: "",
  properties: {
    shape: "chronograf-v2",
    axes: {
      x: {
        label: "",
        prefix: "",
        suffix: "",
        bounds: ["", ""],
        base: "10",
        scale: "linear",
      },
      y: {
        label: "",
        prefix: "",
        suffix: "",
        bounds: ["", ""],
        base: "10",
        scale: "linear",
      },
    },
    type: "xy",
    staticLegend: {
      hide: true,
    },
    geom: "monotoneX",
    colors: [
      darkTheme.palette.primary.main,
      darkTheme.palette.secondary.main,
      darkTheme.palette.warning.main,
      darkTheme.palette.text.secondary,
    ],
    note: "",
    showNoteWhenEmpty: false,
    xColumn: "_time",
    generateXAxisTicks: [],
    xTotalTicks: 0,
    xTickStart: 0,
    xTickStep: 0,
    yColumn: "_value",
    generateYAxisTicks: [],
    yTotalTicks: 0,
    yTickStart: 0,
    yTickStep: 0,
    shadeBelow: false,
    position: "overlaid",
    timeFormat: "",
    hoverDimension: "auto",
    legendColorizeRows: false,
    legendHide: false,
    legendOpacity: 1,
    legendOrientationThreshold: 0,
  },
}

export const singleStatView = {
  id: "",
  name: "",
  properties: {
    shape: "chronograf-v2",
    type: "single-stat",
    queries: [],
    prefix: "",
    tickPrefix: "",
    suffix: "",
    tickSuffix: "",
    colors: [],
    decimalPlaces: {
      isEnforced: true,
      digits: 0,
    },
    note: "",
    showNoteWhenEmpty: false,
  },
}

export const pieView = {
  name: "",
  nameKey: "",
  properties: {
    length: { isEnforced: true, digits: 0 },
    prefix: "",
    suffix: "",
    type: "pie",
  },
}
