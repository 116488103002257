import type { FC } from "react"
import { Fragment, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Divider, Grid, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import fp from "lodash/fp"

import usePermissions from "helpers/hooks/usePermissions"
import { ScrollbarContainer, SectionCard, SectionCardHeader } from "../styled/containers"
import Spinner from "../common/Spinner"
import { useOrgUsersQuery } from "../../features/api"
import CreateUserButton from "./CreateUserButton"

interface OrgUsersProps {
  orgId: number | null
}

const OrgUsers: FC<OrgUsersProps> = ({ orgId }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isEditor } = usePermissions()
  const { currentData: users, isFetching: usersFetching } = useOrgUsersQuery(
    {
      orgId: orgId as number,
    },
    { skip: !orgId },
  )

  const sortedUsers = useMemo(
    () => fp.sortBy(({ username }) => username.toLowerCase(), users),
    [users],
  )

  return (
    <SectionCard>
      <SectionCardHeader sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography component="h5" variant="h5">
          {t("generic.USERS")}
        </Typography>
        {isEditor && <CreateUserButton />}
      </SectionCardHeader>
      {usersFetching ? (
        <Spinner />
      ) : (
        <ScrollbarContainer
          sx={{
            width: "100%",
            p: 2,
            maxHeight: "500px",
            overflow: "auto",
          }}
        >
          <Grid container>
            {sortedUsers?.map(({ username, role, id }) => (
              <Fragment key={id}>
                <Grid item xs={8}>
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "pre",
                      pr: "1rem",
                    }}
                  >
                    {username}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    sx={{
                      color: theme.palette.grey[400],
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {role ? role : "-"}
                  </Typography>
                </Grid>
                <Divider
                  sx={{
                    my: 2,
                    width: "100%",
                  }}
                />
              </Fragment>
            ))}
          </Grid>
        </ScrollbarContainer>
      )}
    </SectionCard>
  )
}

export default OrgUsers
