import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useOrgsQuery } from "../../features/api"
import {
  selectCurrentUser,
  selectCurrentUserGlobalRole,
} from "../../features/store/authSlice"
import {
  selectCurrentOrgId,
  selectCurrentOrgRole,
  setOrg,
  setOrgRole,
} from "../../features/store/orgSlice"
import { ORGS_TIME, USER_ROLES } from "../../helpers/utils/constants"

const useOrgs = () => {
  const dispatch = useDispatch()
  const { orgId: urlOrgId } = useParams()
  const [orgsInfoLoading, setOrgsInfoLoading] = useState()
  const currentOrgId = useSelector(selectCurrentOrgId)
  const user = useSelector(selectCurrentUser)
  const role = useSelector(selectCurrentOrgRole)
  const userGlobalRole = useSelector(selectCurrentUserGlobalRole)
  // FIXME: the role of the current user needs to be part of the reply from the useOrgsQuery
  // current user roles:
  //   admin: when global role is backoffice_admin or reseller.
  //   viewer: when username (mail address) contains the string "viewer".
  //   editor: default.
  const userRole =
    userGlobalRole === "backoffice_admin" || userGlobalRole === "reseller"
      ? USER_ROLES.admin
      : user?.includes("viewer")
      ? USER_ROLES.viewer
      : USER_ROLES.editor
  const {
    data: orgs,
    isFetching: orgsFetching,
    isLoading: orgsLoading,
    isError,
  } = useOrgsQuery(
    {
      user,
    },
    {
      pollingInterval: ORGS_TIME,
    },
  )

  const urlOrgInUserOrgs = useMemo(
    () => orgs?.find((org) => urlOrgId && String(org.id) === urlOrgId),
    [orgs, urlOrgId],
  )

  const getOrgById = (orgId) => orgId && orgs?.find(({ id }) => id === orgId)

  useEffect(
    () => {
      const dispatchOrgInfo = async () => {
        if (orgsFetching) {
          // We are still loading the orgs
        } else if (urlOrgInUserOrgs && String(currentOrgId) !== urlOrgId) {
          dispatch(
            setOrg({
              id: urlOrgInUserOrgs.id,
              role: userRole,
              name: urlOrgInUserOrgs.name,
            }),
          )
        } else if (orgs?.find(({ id }) => id === currentOrgId)) {
          // No need to switch orgs, the current one is a valid option
        } else if (orgs?.length > 0) {
          dispatch(
            setOrg({
              id: orgs[0]?.id,
              role: userRole,
              name: orgs[0]?.name,
            }),
          )
        } else {
          dispatch(
            setOrg({
              id: null,
              role: null,
              name: null,
            }),
          )
        }
      }
      const setOrgInfo = async () => {
        if (orgsFetching) {
          setOrgsInfoLoading(true)
        }
        await dispatchOrgInfo()
        setOrgsInfoLoading(false)
      }
      setOrgInfo()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orgs, orgsFetching, dispatch],
  )

  useEffect(() => {
    if (role !== userRole) {
      dispatch(setOrgRole(userRole))
    }
  }, [role, userRole, dispatch])

  const dispatchOrg = ({ id, name }) => {
    dispatch(setOrg({ id, role: userRole, name }))
  }

  return {
    orgs,
    orgsFetching,
    orgsLoading,
    orgsInfoLoading,
    isError,
    dispatchOrg,
    getOrgById,
  }
}

export default useOrgs
