import type { FC, ReactElement } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import type { Theme } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import useTheme from "@mui/material/styles/useTheme"
import useMediaQuery from "@mui/material/useMediaQuery"
import { t } from "i18next"

import type { ZxcvbnResult } from "@zxcvbn-ts/core"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import {
  AT_LEAST_ONE_LOWERCASE_AND_ONE_UPPERCASE,
  AT_LEAST_ONE_NUMBER,
  AT_LEAST_ONE_SPECIAL_CHAR,
  MIN_8_CHAR,
} from "../../../helpers/utils/constants"
import PasswordStrengthIndicator from "./PasswordStrengthIndicator"

interface IPasswordStrengthIndicator {
  children: ReactElement
  password: string
  open: boolean
  passwordStrength?: ZxcvbnResult
}

const TooltipPasswordValidation: FC<IPasswordStrengthIndicator> = ({
  children,
  password,
  passwordStrength,
  open,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"))
  const theme = useTheme()

  const itemsToValidate = (
    testValue: RegExp | boolean,
    password: string,
    text: string,
  ) => {
    const isValid = typeof testValue === "boolean" ? testValue : testValue.test(password)

    return (
      <Stack direction="row" gap="5px">
        <Box pr="5px">
          {!isValid ? (
            <HighlightOffIcon sx={{ fontSize: 16 }} color="error" />
          ) : (
            <CheckCircleOutlineIcon sx={{ fontSize: 16 }} color="success" />
          )}
        </Box>
        <Typography fontWeight={100}>{t(text)}</Typography>
      </Stack>
    )
  }

  return (
    <Tooltip
      arrow
      PopperProps={{
        disablePortal: true,
        sx: {
          maxWidth: "270px",
          "& .MuiTooltip-tooltip": {
            background: `${theme.palette.background.paper}`,
            color: `${theme.palette.text.primary}`,
            padding: "18px 23px",
            top: "-5px",
            boxShadow: 3,
          },
          "& .MuiTooltip-tooltip ::before": {
            background: `${theme.palette.background.paper}`,
            boxShadow: 3,
          },
        },
      }}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement={isDesktop ? "right" : "bottom"}
      title={
        <Box>
          <Typography variant="h6" pb="8px">
            {t("register.NEW_PASSWORD_VALIDATION")}:{" "}
          </Typography>
          <Stack gap="0px">
            <>
              {itemsToValidate(
                MIN_8_CHAR,
                password,
                `${t("register.FIELD_MIN_CHAR", {
                  minLength: 8,
                })}`,
              )}
            </>
            <>
              {itemsToValidate(
                AT_LEAST_ONE_LOWERCASE_AND_ONE_UPPERCASE,
                password,
                "register.MUST_CONTAIN_UPPER_AND_LOWER",
              )}
            </>
            <>
              {itemsToValidate(
                AT_LEAST_ONE_NUMBER,
                password,
                "register.MUST_CONTAIN_NUMBER",
              )}
            </>
            <>
              {itemsToValidate(
                AT_LEAST_ONE_SPECIAL_CHAR,
                password,
                "register.MUST_CONTAIN_SPECIAL_CHAR",
              )}
            </>
          </Stack>
          <Box pt="25px" pb="10px">
            <PasswordStrengthIndicator passwordStrength={passwordStrength} />
          </Box>
        </Box>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  )
}

export default TooltipPasswordValidation
