import type { FC } from "react"
import { useEffect, useState } from "react"
import type { ChangeHandler, UseFormReturn } from "react-hook-form"
import { useTranslation } from "react-i18next"
import type { ZxcvbnResult } from "@zxcvbn-ts/core"
import { zxcvbn } from "@zxcvbn-ts/core"
import { setZxcvbnOptions } from "../../helpers/utils/lazyLoadDictionaries"

import {
  AT_LEAST_ONE_LOWERCASE_AND_ONE_UPPERCASE,
  AT_LEAST_ONE_NUMBER,
  AT_LEAST_ONE_SPECIAL_CHAR,
  MIN_8_CHAR,
  PASSWORD,
} from "../../helpers/utils/constants"
import TooltipPasswordValidation from "./password/TooltipPasswordValidation"
import ControlledPasswordInput from "./ControlledPasswordInput"

interface INewPasswordInput {
  methods: UseFormReturn<any>
  name?: string
}

const NewPasswordInput: FC<INewPasswordInput> = ({ methods, name = "newPassword" }) => {
  const { t, i18n } = useTranslation()
  const [passwordStrength, setPasswordStrength] = useState<ZxcvbnResult>()
  const [isFocused, setIsFocused] = useState(false)
  const { watch } = methods
  const passwordValue = watch("newPassword")

  useEffect(() => {
    const loadLibrary = async () => await setZxcvbnOptions(i18n.language)
    loadLibrary()
  }, [i18n.language])

  return (
    <TooltipPasswordValidation
      open={isFocused && passwordValue.length >= 1}
      password={passwordValue}
      passwordStrength={passwordStrength}
    >
      <>
        <ControlledPasswordInput
          size="small"
          variant="outlined"
          label={t("change_password.NEW_PASSWORD")}
          {...methods.register(name, {
            required: `${t("generic.FIELD_REQUIRED")}`,
            validate: {
              goodPasswordStrength: (value) => {
                const results = zxcvbn(value)
                setPasswordStrength(results)
                return (results?.score ?? 0) >= PASSWORD.MIN_VALID_STRENGTH
              },
              allTheValidators: (value) => {
                const validators = [
                  MIN_8_CHAR,
                  AT_LEAST_ONE_LOWERCASE_AND_ONE_UPPERCASE,
                  AT_LEAST_ONE_NUMBER,
                  AT_LEAST_ONE_SPECIAL_CHAR,
                ]
                const checks = validators
                  .map((validator) => (validator.test(value ?? "") ? 1 : 0))
                  .reduce((acc: number, result) => acc + result, 0)
                return checks >= 2 // At least should meet 2 conditions
              },
            },
          })}
          onFocus={() => setIsFocused(true)}
          onBlur={((_e: Event) => setIsFocused(false)) as ChangeHandler}
        />
      </>
    </TooltipPasswordValidation>
  )
}

export default NewPasswordInput
