import { useOutletContext } from "react-router-dom"

import { spanPlotDefs } from "helpers/config/plots"
import SentrisenseDashboard from "widgets/device/Dashboard"
import GeneralDashboard from "widgets/device/DeviceDashboard"
import { BodyContainer } from "widgets/styled/containers"

import type { DeviceDashboardOutletContext } from "types/dashboard.types"

// This component had to be created so that both Span tab and Info tab could share the index route
function MainDashboard() {
  const { id: deviceId, device } = useOutletContext<DeviceDashboardOutletContext>()

  const config = {
    type: "span",
    plotDefs: spanPlotDefs,
  }

  return (
    <BodyContainer disableGutters maxWidth="xl">
      {device.device_type === "sentrisense" ? (
        <SentrisenseDashboard deviceId={deviceId} config={config} />
      ) : (
        <GeneralDashboard id={deviceId} />
      )}
    </BodyContainer>
  )
}

export default MainDashboard
