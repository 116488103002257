import type { FC } from "react"
import { Box, Skeleton } from "@mui/material"

import type { LastLocationsRes } from "types/telemetries.types"

import GeoPlot from "./GeoPlot"

interface GeolocationPlotProps {
  data: LastLocationsRes | null
  isLoading: boolean
  mapHeight: number | string
}

const GeolocationPlot: FC<GeolocationPlotProps> = ({ data, isLoading, mapHeight }) => {
  if (isLoading || !data) {
    return <Skeleton variant="rectangular" height={mapHeight} />
  }

  return data.length > 0 ? (
    <Box maxWidth="xl" sx={{ height: mapHeight }}>
      <GeoPlot data={data} />
    </Box>
  ) : (
    <></>
  )
}

export default GeolocationPlot
