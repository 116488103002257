import fp from "lodash/fp"

import type { IPlotDef } from "types/dashboard.types"
import { singleStatView } from "./base"

const lastTemperaturePlotView = fp.merge(singleStatView, {
  name: "Last temperature",
  nameKey: "LAST_TEMPERATURE",
  properties: {
    footerFields: ["_time"],
    suffix: "°C",
    size: "large",
    decimalPlaces: {
      digits: 2,
    },
    formatUnits: true,
  },
})

export const lastTemperaturePlotDef: IPlotDef = {
  view: lastTemperaturePlotView,
  draggable: true,
  reqConfig: {
    fields: [
      "sensors.thermal.raw.frame.cable_estimate",
      "sensors.thermal.temperatures.frame.cable_estimate",
    ],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}

const lastSagPlotView = fp.merge(singleStatView, {
  name: "Last sag value",
  nameKey: "LAST_SAG",
  properties: {
    footerFields: ["_time"],
    suffix: "m",
    size: "large",
    decimalPlaces: {
      digits: 2,
    },
  },
})

export const lastSagPlotDef: IPlotDef = {
  view: lastSagPlotView,
  draggable: true,
  reqConfig: {
    fields: ["derived.sag.value"],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}

const maxDisplacementAmplitudePlotView = fp.merge(singleStatView, {
  name: "Max displacement amplitude",
  nameKey: "MAX_DISPLACEMENT_AMPLITUDE",
  properties: {
    footerFields: ["_time"],
    suffix: "m",
    size: "large",
    decimalPlaces: {
      digits: 2,
    },
    formatUnits: true,
  },
})

export const maxDisplacementAmplitudePlotDef: IPlotDef = {
  view: maxDisplacementAmplitudePlotView,
  draggable: true,
  reqConfig: {
    fields: ["sensors.motion.fatigue.max_displacement"],
    field_pivot: false,
    method: ["max"],
    group: ["id", "_field"],
  },
}

const lastWindSpeedPlotView = fp.merge(singleStatView, {
  name: "Last wind speed value",
  nameKey: "LAST_T_WIND_SPEED",
  properties: {
    footerFields: ["_time"],
    suffix: "m/s",
    size: "large",
    decimalPlaces: {
      digits: 2,
    },
    formatUnits: true,
  },
})

export const lastWindSpeedPlotDef: IPlotDef = {
  view: lastWindSpeedPlotView,
  draggable: true,
  reqConfig: {
    fields: ["sensors.motion.aeolian.transverse_wind_speed_estimate"],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}

const lastCurrentPlotView = fp.merge(singleStatView, {
  name: "Last current",
  nameKey: "LAST_CURRENT",
  properties: {
    footerFields: ["_time"],
    suffix: "A",
    size: "large",
    decimalPlaces: {
      digits: 2,
    },
    formatUnits: true,
  },
})

export const lastCurrentPlotDef: IPlotDef = {
  view: lastCurrentPlotView,
  draggable: true,
  reqConfig: {
    fields: ["derived.current.value"],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}

export const dataEntriesPlotView = fp.merge(singleStatView, {
  name: "Data Entries",
  nameKey: "DATA_ENTRIES",
})

export const countPlotView = fp.merge(singleStatView, {
  name: "Count",
  nameKey: "COUNT",
})

export const firstMessagePlotView = fp.merge(singleStatView, {
  name: "First Message",
  nameKey: "FIRST_MESSAGE",
})

export const lastMessagePlotView = fp.merge(singleStatView, {
  name: "Last Message",
  nameKey: "LAST_MESSAGE",
})
