import { useLayoutEffect, useReducer } from "react"
import type { FC } from "react"

import { useDashboardByDeviceQuery } from "features/api"
import { cellStateReducer, layoutReducer } from "helpers/reducers/dashboardReducers"
import Message from "widgets/common/Message"
import Spinner from "widgets/common/Spinner"

import DashboardContext from "./DashboardContext"
import DashboardLayout from "./DashboardLayout"

interface DeviceDashboardProps {
  id: string
}

const DeviceDashboard: FC<DeviceDashboardProps> = ({ id }) => {
  const [layout, dispatchLayout] = useReducer(layoutReducer, {})
  const [cellState, dispatchCellState] = useReducer(cellStateReducer, {})
  const {
    currentData: dashboard,
    isFetching: dashboardIsFetching,
    isSuccess: dashboardIsSuccess,
    isError: dashboardIsError,
  } = useDashboardByDeviceQuery({
    id,
  })

  useLayoutEffect(() => {
    if (dashboardIsSuccess && dashboard) {
      for (const cell of dashboard.cells) {
        dispatchLayout({
          type: "ADD_CELL_REF",
          payload: {
            id: cell.id,
            i: cell.id,
            x: cell.x,
            y: cell.y,
            w: cell.w,
            minH: 0,
            h: 0,
          },
        })
      }
    }
  }, [dashboard, dashboardIsSuccess])

  const cellsAreFetching = Object.values(cellState).some((value) => value)
  const isData = Object.values(layout).some((value) => value.h !== 0)

  return (
    <>
      <DashboardContext.Provider
        value={{
          dashboard,
          id,
          layout,
          cellState,
          cellsAreFetching,
          dispatchLayout,
          dispatchCellState,
        }}
      >
        {dashboardIsFetching ? (
          <Spinner />
        ) : dashboardIsError ? (
          <Message messageKey={"device.CHART_CREATION_ERROR"} type="error" />
        ) : (
          dashboardIsSuccess && (
            <>
              <DashboardLayout />
              {!cellsAreFetching && !isData && (
                <Message messageKey={"generic.NO_DATA_IN_TIME_WINDOW"} />
              )}
            </>
          )
        )}
      </DashboardContext.Provider>
    </>
  )
}

export default DeviceDashboard
