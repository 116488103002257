import * as L from "leaflet"

import defaultIconUrl from "../../images/marker.png"
import defaultIconRetinaUrl from "../../images/marker-x2.png"
import triggeredIconUrl from "../../images/marker-red.png"
import triggeredIconRetinaUrl from "../../images/marker-red-x2.png"

//MAP ICONS
export const TRIGGERED_DEVICE_MARKER = L.icon({
  iconUrl: triggeredIconUrl,
  iconRetinaUrl: triggeredIconRetinaUrl,
  iconSize: [25, 37],
})
export const DEFAULT_DEVICE_MARKER = L.icon({
  iconUrl: defaultIconUrl,
  iconRetinaUrl: defaultIconRetinaUrl,
  iconSize: [25, 37],
})

export function getMarkerIcon(state: string) {
  switch (state) {
    case "triggered":
      return TRIGGERED_DEVICE_MARKER
    default:
      return DEFAULT_DEVICE_MARKER
  }
}
