import type { FC } from "react"
import { Marker } from "react-leaflet"

import { MarkerTooltip } from "../../styled/map"
import type { DeviceWLocationNStatesType } from "../../../types/device.types"
import { getMarkerIcon } from "../../../helpers/utils/map"
import GeoPopup from "./GeoPopup"

interface IDeviceMapMarkerProps {
  device: DeviceWLocationNStatesType
  alertState: string
}

const DeviceMapMarker: FC<IDeviceMapMarkerProps> = ({ device, alertState }) => {
  const { lat, lon, name } = device

  return (
    <Marker
      position={[lat, lon]}
      icon={getMarkerIcon(alertState)}
      zIndexOffset={alertState === "triggered" ? 500 : 100}
    >
      <GeoPopup device={device} />
      {name && (
        <MarkerTooltip
          alertState={alertState}
          direction="bottom"
          offset={[0, 25]}
          opacity={1}
          permanent
        >
          {name}
        </MarkerTooltip>
      )}
    </Marker>
  )
}

export default DeviceMapMarker
