import type { FC, PropsWithChildren, ReactNode } from "react"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import { useTheme } from "@mui/system"

interface ILoadingButtonProps extends PropsWithChildren<any> {
  loading: boolean
  styles?: object
  variant?: "outlined" | "text" | "contained"
  icon?: ReactNode
  type?: "submit" | "button"
  "aria-label"?: React.ComponentProps<"button">["aria-label"]
}
const LoadingButton: FC<ILoadingButtonProps> = ({
  children,
  loading,
  styles,
  onClick,
  variant = "text",
  icon,
  disabled = false,
  type = "submit",
  "aria-label": ariaLabel = "submit",
}) => {
  const theme = useTheme()

  return (
    <Button
      variant={variant}
      onClick={onClick}
      disabled={disabled || loading}
      sx={{
        display: "flex",
        gap: 1,
        pr: 2,
        pl: 2,
        ...styles,
      }}
      type={type}
      aria-label={ariaLabel}
    >
      {loading && (
        <CircularProgress
          size={20}
          data-testid="progressbar"
          sx={{
            color: variant === "contained" ? "inherit" : theme.palette.text.primary,
            m: icon ? "2px" : "0",
          }}
        />
      )}
      {!loading && icon}
      {children}
    </Button>
  )
}

export default LoadingButton
