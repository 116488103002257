import { useTranslation } from "react-i18next"

import Title from "widgets/common/Title"
import CreateGroupButton from "widgets/deviceGroups/editor/CreateGroupButton"
import { HeaderContainer } from "widgets/styled/containers"
import usePermissions from "helpers/hooks/usePermissions"
import MapSection from "widgets/deviceGroups/MapSection"
import GroupCardsSection from "widgets/deviceGroups/GroupCardsSection"

interface DeviceGroupsProps {
  showMap?: boolean
}

function DeviceGroups({ showMap = true }: DeviceGroupsProps) {
  const { t } = useTranslation()
  const { isEditor } = usePermissions()

  return (
    <>
      <HeaderContainer>
        <Title text={t("device_groups.DEVICE_GROUPS")} />
        {isEditor && <CreateGroupButton />}
      </HeaderContainer>
      {showMap && <MapSection />}
      <GroupCardsSection />
    </>
  )
}

export default DeviceGroups
