import type { FC } from "react"
import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import LinearProgress from "@mui/material/LinearProgress"
import type { ZxcvbnResult } from "@zxcvbn-ts/core"
import type { SimplePaletteColorOptions } from "@mui/material"
import useTheme from "@mui/material/styles/useTheme"
import Fade from "@mui/material/Fade"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { t } from "i18next"
import _ from "lodash"
import type { IStrengthState, StrengthLabel } from "../../../types/password.types"
import { Strength } from "../../../types/password.types"

interface IPasswordStrengthIndicator {
  passwordStrength?: ZxcvbnResult
}

const PasswordStrengthIndicator: FC<IPasswordStrengthIndicator> = ({
  passwordStrength,
}) => {
  const [strength, setStrength] = useState<IStrengthState>({
    label: "veryWeak",
    score: 0,
    percentage: 0,
  })

  const maxStrength = 4

  const percentageOfStrengthCalc = (value: number) => {
    let strength = value
    if (value === 0) {
      strength = 1
    }
    return (strength / maxStrength) * 100
  }

  useEffect(() => {
    const percentage = percentageOfStrengthCalc(passwordStrength?.score ?? 0)
    const score = passwordStrength?.score ?? 0

    setStrength({
      label: _.camelCase(Strength[score]) as StrengthLabel,
      score,
      percentage,
    })
  }, [passwordStrength])

  const theme = useTheme()

  const divider = (value: number) => {
    return (
      <Stack
        key={value}
        zIndex={100}
        sx={{
          position: "absolute",
          backgroundColor: `${theme.palette.background.paper}`,
          height: "100%",
          display: "block",
          width: "4px",
          top: 0,
          right: `${value}%`,
        }}
      ></Stack>
    )
  }

  return (
    <>
      <Box pb="10px">
        <Typography pb="2px" textAlign="left" fontWeight={600} variant="subtitle2">
          {t(`password_strength.STRENGTH`)}:{" "}
          {t(`password_strength.${strength.label.toUpperCase()}`)}
        </Typography>
        <Stack position="relative">
          <Stack>
            {[...Array(maxStrength)].map((_, i: number) => {
              const step = 100 / maxStrength
              if (i > 0) {
                return divider(step * i)
              }
            })}
          </Stack>
          <LinearProgress
            color="primary"
            style={{
              backgroundColor: `${theme.palette.text.primary}`,
              height: "3px",
            }}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": {
                background: `${
                  (theme.palette[strength.label] as SimplePaletteColorOptions).main
                }`,
              },
            }}
            variant="determinate"
            value={strength.percentage}
          />
        </Stack>
      </Box>

      <Fade in={(passwordStrength?.feedback?.suggestions?.length ?? 0) > 0}>
        <Box>
          {passwordStrength?.feedback.suggestions.map(
            (suggestion: string, index: number) => {
              return (
                <Typography key={index} textAlign="left">
                  {suggestion}
                </Typography>
              )
            },
          )}
        </Box>
      </Fade>
    </>
  )
}

export default PasswordStrengthIndicator
