import type { Middleware, PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { TWarningPassword, TFailurePassword } from "../../types/password.types"
import type { RootState } from "."

interface AuthState {
  user: string | null
  token: string | null
  code?: TWarningPassword | TFailurePassword
  globalRole: string | null
}

const initialState: AuthState = {
  user: null,
  token: null,
  code: undefined,
  globalRole: null,
}

function getPersistentAuth(): AuthState {
  // Lazy load the state from the localStorage or fallback to the initialState
  try {
    const authState = JSON.parse(localStorage.getItem("auth") as string)
    const { user, token, code, globalRole } = authState
    return {
      user,
      token,
      code,
      globalRole: globalRole ?? initialState.globalRole,
    }
  } catch (error) {
    return initialState
  }
}

type UpdateCode = { code: TWarningPassword | TFailurePassword | undefined }
type UpdateToken = { token: string }

export const authSlice = createSlice({
  name: "auth",
  initialState: getPersistentAuth,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token, code, globalRole } }: PayloadAction<AuthState>,
    ): AuthState => ({
      ...state,
      user,
      token,
      code,
      globalRole,
    }),
    updateCode: (state, { payload: { code } }: PayloadAction<UpdateCode>): AuthState => ({
      ...state,
      code,
    }),
    updateToken: (
      state,
      { payload: { token } }: PayloadAction<UpdateToken>,
    ): AuthState => ({
      ...state,
      token,
    }),
    invalidateCredentials: (): AuthState => initialState,
  },
})

export const { setCredentials, updateCode, updateToken, invalidateCredentials } =
  authSlice.actions
export const authReducer: Reducer<AuthState> = authSlice.reducer
export default authReducer

export const selectCode = (state: RootState) => state.auth.code
export const selectToken = (state: RootState) => state.auth.token
export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectCurrentUserGlobalRole = (state: RootState) => state.auth.globalRole

// Store the token in the localStorage, while keeping the reducers as pure functions
export const authMiddleware: Middleware = (store) => (next) => (action) => {
  // console.group(action.type)
  const result = next(action)
  switch (action.type) {
    case "auth/setCredentials": // fallthrough
    case "auth/updateCode":
    case "auth/updateToken":
    case "auth/invalidateCredentials": {
      const { auth } = store.getState()
      // console.log(`store: ${JSON.stringify(auth, undefined, 2)}`)
      localStorage.setItem("auth", JSON.stringify(auth))
      // console.log("done")
      break
    }
    default:
      break
  }
  // console.groupEnd()
  return result
}
