import { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import fp from "lodash/fp"

import {
  selectDeviceDateRange,
  selectEventsDateRange,
  updateDeviceDateRange,
  updateEventsDateRange,
} from "features/store/dateRangeSlice"
import type { DateRange } from "types/date.types"
import { formatSearchParams } from "helpers/utils/dateUtils"

const useDateRange = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const deviceDateRange = useSelector(selectDeviceDateRange)
  const eventsDateRange = useSelector(selectEventsDateRange)

  const dispatchDeviceDateRange = useCallback(
    (dateRange: DateRange) => {
      dispatch(updateDeviceDateRange(dateRange))
    },
    [dispatch],
  )
  const dispatchEventsDateRange = useCallback(
    (dateRange: DateRange) => {
      dispatch(updateEventsDateRange(dateRange))
    },
    [dispatch],
  )

  const deviceSearchParams = useMemo(
    () => formatSearchParams(deviceDateRange),
    [deviceDateRange],
  )
  const eventsSearchParams = useMemo(
    () => formatSearchParams(eventsDateRange),
    [eventsDateRange],
  )

  const isDeviceDateRangeDirty = useMemo(() => {
    const range = searchParams.get("range")
    const fromDate = searchParams.get("fromDate")
    const toDate = searchParams.get("toDate")
    const dateRangeParams = { range, fromDate, toDate }

    if (range && range !== "custom" && range !== deviceDateRange?.range) {
      return true
    } else if (
      range &&
      range === "custom" &&
      !fp.isEqual(dateRangeParams, deviceDateRange)
    ) {
      return true
    } else {
      return false
    }
  }, [deviceDateRange, searchParams])

  const isDeviceDateRangeValid = useMemo(
    () => !!deviceDateRange && !isDeviceDateRangeDirty,
    [deviceDateRange, isDeviceDateRangeDirty],
  )

  const isEventsDateRangeDirty = useMemo(() => {
    const range = searchParams.get("range")
    const fromDate = searchParams.get("fromDate")
    const toDate = searchParams.get("toDate")
    const dateRangeParams = { range, fromDate, toDate }

    if (range && range !== "custom" && range !== eventsDateRange?.range) {
      return true
    } else if (
      range &&
      range === "custom" &&
      !fp.isEqual(dateRangeParams, eventsDateRange)
    ) {
      return true
    } else {
      return false
    }
  }, [eventsDateRange, searchParams])

  const isEventsDateRangeValid = useMemo(
    () => !!eventsDateRange && !isEventsDateRangeDirty,
    [eventsDateRange, isEventsDateRangeDirty],
  )

  return {
    deviceDateRange,
    eventsDateRange,
    dispatchDeviceDateRange,
    dispatchEventsDateRange,
    deviceSearchParams,
    eventsSearchParams,
    isDeviceDateRangeValid,
    isEventsDateRangeValid,
  }
}

export default useDateRange
