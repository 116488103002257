import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material"

import { CodeBox } from "widgets/styled/containers"
import CustomLink from "widgets/common/CustomLink"
import CardWithTitle from "widgets/common/CardWithTitle"
import { Paragraph, Subtitle } from "./wrappers"

interface ApiDocEsProps {
  url: string
}

export default function ApiDocEs({ url }: ApiDocEsProps) {
  return (
    <Stack maxWidth="xl" sx={{ pt: 2, gap: 2 }}>
      <CardWithTitle titleKey="api.AUTHENTICATION">
        <Subtitle>Autenticación del Token</Subtitle>
        <Paragraph>
          La autenticación basada en el token, depende de un token firmado que es enviado
          al servidor en cada una de las peticiones.
        </Paragraph>
        <Paragraph>
          Tener en cuenta que el token da acceso para realizar peticiones en nombre de una
          organización. Estos valores deben considerarse tan sensibles como contraseñas y
          no deben ser compartidas o distribuidas a sujetos no confiables.
        </Paragraph>

        <Subtitle>Creando el Token</Subtitle>
        <Paragraph>
          Para acceder a la API, es necesario un token. Puede crear el token en la pestaña
          <CustomLink href={"create-token"} component="span" underlineHover>
            {" "}
            &apos;API &rarr; Token&apos;{" "}
          </CustomLink>
          .
        </Paragraph>

        <Subtitle>Usando el Token</Subtitle>
        <Paragraph>
          Cada una de las peticiones autenticadas debe incluir un encabezado de
          autorización con el token.
        </Paragraph>

        <Subtitle>Ejemplo con cURL:</Subtitle>
        <CodeBox component="pre">
          curl -H &apos;Authorization: Bearer MY_TOKEN&apos; -X {`"${url}/MY_REQUEST"`}
        </CodeBox>

        <Subtitle>Ejemplo con Python:</Subtitle>
        <CodeBox component="pre">
          {`import requests
response = requests.get('${url}/MY_REQUEST', headers={'Authorization': 'Bearer MY_TOKEN'})`}
        </CodeBox>
        <Subtitle>Certificados SSL</Subtitle>
        <Paragraph>
          Todas las peticiones a la API siempre serán a través de HTTPS. El certificado
          utilizado en el servidor de la API es{" "}
          <b>renovado automáticamente cada 60 días</b>. Por favor tener esta información
          en cuenta a la hora de escribir clientes de la API.
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.RESPONSE">
        <Paragraph>
          Vea{" "}
          <CustomLink
            href="https://developer.mozilla.org/es/docs/Web/HTTP/Status"
            component="span"
            target="_blank"
            underlineHover
          >
            &apos;Estados de Respuesta&apos;
          </CustomLink>{" "}
          para una lista completa de códigos estado.
        </Paragraph>
        <Subtitle>Respuestas Correctas</Subtitle>

        <Subtitle>200</Subtitle>
        <Paragraph>
          Esta es la respuesta que se obtiene cuando la consulta fue exitosa.
        </Paragraph>
        <Subtitle>Respuestas de error</Subtitle>
        <Subtitle>401 (No autorizado)</Subtitle>
        <Paragraph>
          Un autenticación con credenciales inválidas devuelverán un error 401 No
          autorizado.
        </Paragraph>
        <CodeBox component="pre">
          {`
{
  "message": "The server could not verify that you are authorized to access the URL requested.
  You either supplied the wrong credentials (e.g. a bad password), or your browser doesn't
  understand how to supply the credentials required."
}
        `}
        </CodeBox>

        <Subtitle>404 (No encontrado)</Subtitle>
        <Paragraph>
          La url utilizada no existe. Chequea si existe un error de tipeo en la url.
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DATA_FORMAT">
        <Subtitle>Fechas</Subtitle>
        <Paragraph>
          Las fechas que envía la API son la cantidad de milisegundos que han pasado desde
          00:00:00 Tiempo Coordinado Universal (UTC), Martes, 1 de Enero, 1970.
        </Paragraph>
        <Paragraph>
          El valor devuelto tiene fracción decimal porque es la forma más precisa que
          permite el SO.
        </Paragraph>
        <Paragraph>
          Una página útil para trabajar con esta clase de formato de fechas es:
          <CustomLink
            href="http://www.epochconverter.com/"
            component="span"
            target="_blank"
            underlineHover
          >
            {" "}
            http://www.epochconverter.com/
          </CustomLink>
          .
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_API">
        <Subtitle>URL del Recurso</Subtitle>
        <Paragraph>{`${url}/v1/device/`}</Paragraph>

        <Subtitle>Descripción del Recurso</Subtitle>
        <Paragraph>
          Devuelve una colección de todos los dispositivos disponibles. Este endpoint{" "}
          <b>no puede ser utilizado</b> por usuarios de tipo <i>reseller</i>
        </Paragraph>

        <Subtitle>Información del Recurso</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Método:</b> GET
            </li>
            <li>
              <b>Formato de respuesta:</b> JSON
            </li>
            <li>
              <b>Requiere autenticación?</b> Sí
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parámetros</Subtitle>
        <Paragraph>Esta operación no requiere parámetros.</Paragraph>

        <Subtitle>Ejemplo de Petición</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/devices/`}</Paragraph>

        <Subtitle>Ejemplo de Respuesta</Subtitle>
        <CodeBox component="pre">
          {`
[
    {
      "device_type": "sentrisense",
      "activated_at": 1674744569600.811,
      "less_id": 12345,
      "name": "device A",
      ...
    },
    {
      "device_type": "weather_station",
      "activated_at": 1674746789101.34,
      "less_id": 67890,
      "name": "device B",
      ...
    },
    ...
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_GROUPS_API">
        <Subtitle>URL del Recurso</Subtitle>
        <Paragraph>{`${url}/v1/device_groups/`}</Paragraph>

        <Subtitle>Descripción del Recurso</Subtitle>
        <Paragraph>
          Devuelve una colección de grupos de dispositivos. Este endpoint{" "}
          <b>no puede ser utilizado</b> por usuarios de tipo <i>reseller</i>
        </Paragraph>

        <Subtitle>Información del Recurso</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Método:</b> GET
            </li>
            <li>
              <b>Formato de respuesta:</b> JSON
            </li>
            <li>
              <b>Requiere autenticación?</b> Sí
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parámetros</Subtitle>
        <Paragraph>Esta operación no requiere parámetros.</Paragraph>

        <Subtitle>Ejemplo de Petición</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/device_groups/`}</Paragraph>

        <Subtitle>Ejemplo de Respuesta</Subtitle>
        <CodeBox component="pre">
          {`
[
  {
    "device_list": [],
    "name": "Group number one"
    ...
  },
  {
    "device_list": [
      {
        "device_type": "sentrisense",
        "activated_at": 1674744569600.811,
        "less_id": 12345,
        "name": "device A",
        ...
      }
    ],
    "name": "Group number two",
    ...
  },
  {
    "device_list": [
      {
        "device_type": "sentrisense",
        "activated_at": 1674744569600.811,
        "less_id": 12345,
        "name": "device A",
        ...
      },
      {
        "device_type": "weather_station",
        "activated_at": 1674746789101.34,
        "less_id": 67890,
        "name": "device B",
        ...
      }
    ],
    "name": "Default Group",
    ...
  }
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.TELEMETRY_API">
        <Subtitle>URL del Recurso</Subtitle>
        <Paragraph>{`${url}/v1/devices/$HARDWARE_ID/telemetry/`}</Paragraph>

        <Subtitle>Descripción del Recurso</Subtitle>
        <Paragraph>
          Devuelve una colección de telemetrías reportadas por un dispositivo específico.
        </Paragraph>

        <Subtitle>Información del Recurso</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Método:</b> GET
            </li>
            <li>
              <b>Formato de respuesta:</b> CSV o JSON
            </li>
            <li>
              <b>Requiere autenticación?</b> Sí
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parámetros</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  hardware_id
                </TableCell>
                <TableCell>
                  Identificador del dispositivo. Este parámetro forma parte de la url del
                  recurso.
                </TableCell>
                <TableCell>Ejemplo de valor: 12345</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  from_date
                </TableCell>
                <TableCell>
                  Se devuelven telemetrías reportadas después de esta fecha. Formato de
                  fecha ISO 8601. Si no se pasa ningún valor, se toma el valor del to_date
                  y se descuenta 1 día por omisión.
                </TableCell>
                <TableCell>
                  Ejemplo de valor: &apos;2015-12-27T03:00:58.562Z&apos;
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  to_date
                </TableCell>
                <TableCell>
                  Se devuelven telemetrías reportadas antes de esta fecha. Formato de
                  fecha ISO 8601. Si no se pasa ningún valor, se toma el valor de fecha
                  actual por omisión.
                </TableCell>
                <TableCell>
                  Ejemplo de valor: &apos;2015-12-27T03:00:58.562Z&apos;
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  limit
                </TableCell>
                <TableCell>
                  El número máximo de filas de telemetrías por campo devueltas. Por
                  omisión es 0 (sin límite).
                </TableCell>
                <TableCell>Ejemplo de valor: 20</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  format
                </TableCell>
                <TableCell>
                  Selecciona entre formato <em>csv</em> o <em>json</em>.
                </TableCell>
                <TableCell>Ejemplo de valor: &apos;json&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field
                </TableCell>
                <TableCell>
                  Selecciona las telemetrías que contengan este campo especificado. Esto
                  puede ser especificado múltiples veces para seleccionar múltiples
                  campos.
                </TableCell>
                <TableCell>Ejemplo de valor: &apos;power.battery.level&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field_prefix
                </TableCell>
                <TableCell>
                  Selecciona las telemetrías que contengan los campos con este prefijo
                  especificado.
                </TableCell>
                <TableCell>Ejemplo de valor: &apos;power.battery.&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field_pivot
                </TableCell>
                <TableCell>
                  Usar el campo de nombre como columnas en el resultado. Por omisión es{" "}
                  <em>false</em>.
                </TableCell>
                <TableCell>Ejemplo de valor: 1</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  only_field_names
                </TableCell>
                <TableCell>
                  Ignora valores y devuelve solo los nombres de los campos. Por omisión es{" "}
                  <em>false</em>.
                </TableCell>
                <TableCell>Ejemplo de valor: 1</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Subtitle>Ejemplo de petición:</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>
          {`${url}/v1/devices/12345/telemetry/?from_date=2024-05-12T00:00:00.000Z&to_date=2024-05-14T00:00:00.000Z&limit=200&format=json`}
        </Paragraph>

        <Subtitle>Ejemplo de respuesta:</Subtitle>
        <CodeBox component="pre">
          {`
[
   {
       "result": "telemetry",
       "table": 0,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T15:13:46.837481+00:00",
       "_value": "2024.02.06.d7bf307",
       "_field": "firmware_version",
       "id": "12345",
       "ingestor": "catapult"
   },
   {
       "result": "telemetry",
       "table": 1,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T23:40:44.999542+00:00",
       "_value": 1.9573431193818354,
       "_field": "sensors.motion.aeolian.transverse_wind_speed_estimate",
       "id": "12345",
       "ingestor": "aeolian.20231115"
   },
   {
       "result": "telemetry",
       "table": 15,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T21:09:36.274419+00:00",
       "_value": 88.5,
       "_field": "power.battery.level",
       "id": "12345",
       "ingestor": "catapult"
   },
   ...
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_GEO_API">
        <Subtitle>URL del Recurso</Subtitle>
        <Paragraph>{`${url}/v1/gps_locations/`}</Paragraph>
        <Subtitle>Descripción del Recurso</Subtitle>
        <Paragraph>Devuelve la última localización reportada por el device.</Paragraph>
        <Subtitle>Información del Recurso</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Método:</b> GET
            </li>
            <li>
              <b>Formato de respuesta:</b> JSON
            </li>
            <li>
              <b>Requiere autenticación?</b> Sí
            </li>
          </ul>
        </Paragraph>
        <Subtitle>Parámetros</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  less_id (necesario)
                </TableCell>
                <TableCell>Identificador del dispositivo</TableCell>
                <TableCell>Ejemplo de valor: 12345</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Subtitle>Ejemplo de petición:</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/gps_locations/?less_id=12345`}</Paragraph>
        <Subtitle>Ejemplo de respuesta:</Subtitle>
        <CodeBox component="pre">
          {`
[
  {
    'result': 'location',
    'table': 0,
    '_time': '2023-08-12T12:51:51.920484+00:00',
    'id': '12345',
    'lat': -40.3465719758353,
    'lon': 175.70627619875916
  }
]
              `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.ALERTS_API">
        <Subtitle>URL del Recurso</Subtitle>
        <Paragraph>{`${url}/v1/alerts/`}</Paragraph>

        <Subtitle>Descripción del Recurso</Subtitle>
        <Paragraph>Devuelve una colección de alertas.</Paragraph>

        <Subtitle>Información del Recurso</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Método:</b> GET
            </li>
            <li>
              <b>Formato de respuesta:</b> JSON
            </li>
            <li>
              <b>Requiere autenticación?</b> Sí
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parámetros</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  less_id (opcional)
                </TableCell>
                <TableCell>El identificador del dispositivo</TableCell>
                <TableCell>Ejemplo de valor: 12345</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  from_date
                </TableCell>
                <TableCell>
                  Se devuelven telemetrías reportadas después de esta fecha. Formato de
                  fecha ISO 8601.
                </TableCell>
                <TableCell>
                  Ejemplo de valor: &apos;2015-12-27T03:00:58.562Z&apos;
                </TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  to_date
                </TableCell>
                <TableCell>
                  Se devuelven telemetrías reportadas de esta fecha. Formato de fecha ISO
                  8601.
                </TableCell>
                <TableCell>
                  Ejemplo de valor: &apos;2015-12-27T03:00:58.562Z&apos;
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Subtitle>Ejemplo de petición:</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>
          {`${url}/v1/alerts/?from_date=2024-05-01T00:00:00.000Z&to_date=2024-05-14T00:00:00.000Z&limit=10&less_id=12345`}
        </Paragraph>

        <Subtitle>Ejemplo de respuesta:</Subtitle>
        <CodeBox component="pre">
          {`
{
  "results": [
    {
      'alert_setting_id': 2345678901234,
      'type': 'sentrisense_motion',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-05-07 06:55:19 GMT: Alert 'Motion' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1715064919519.014,
      'alert_name': 'Motion',
      'enterprise': 1,
      'id': 34567890
    },
    {
      'alert_setting_id': 72363857920,
      'type': 'threshold',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-04-30 05:57:22 GMT: Alert 'Low Battery' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1714456642977.832,
      'alert_name': 'Low Battery',
      'enterprise': 1,
      'id': 2908490752,
    },
    {
      'type': 'threshold',
      'notifications': 1,
      'summary': "2024-04-30 00:59:04 GMT: Alert 'Low Battery' on the device 'device A'",
      'device_name': 'device A',
      'sent_by_email': True,
      'alert_setting_id': 72363857920,
      'status': 11,
      'enterprise': 1,
      'less_id': 12345,
      'resolved': False,
      'viewed': False,
      'triggered': True,
      'alert_name': 'Low Battery',
      'timestamp': 1714438744589.686,
      'id': 537513984,
    },
    {
      'alert_setting_id': 1234567890123,
      'type': 'sentrisense_motion',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-05-10 21:51:50 GMT: Alert 'Motion' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1715377910.252,
      'alert_name': 'Motion',
      'enterprise': 1,
      'id': 45678901
    },
  ...
  ],
  "limit": 10,
  "next": "Ck8KFgoJdGltZXN0YW1wEgkhPf6s9631eEISMWoOc35sZXNzLWJhY2tlbmRyEgsSBUFsZXJ0GICAxLmD24UIDKIBCnByb2R1Y3Rpb24YACAB"
]
        `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.EXTRA_DOCUMENTATION">
        <Paragraph>
          Accede{" "}
          <CustomLink
            href="https://colab.research.google.com/drive/1I5qn8JluLtAPrN1BtLkoa3sG0DtiTXXW?usp=sharing"
            component="span"
            underlineHover
            target="_blank"
          >
            aquí
          </CustomLink>{" "}
          a un archivo de Python notebook interactivo con ejemplos adicionales para
          experimentar con consultas predefinidas.
        </Paragraph>
      </CardWithTitle>
    </Stack>
  )
}
