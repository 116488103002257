import { lazy, Suspense } from "react"
import { Navigate, Route, Routes as RouterRoutes, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import DeviceData from "pages/orgs/Device/DeviceData"
import MainDashboard from "pages/orgs/Device/MainDashboard"
import { selectCurrentUser } from "features/store/authSlice"
import { DEFAULT_TARGET } from "helpers/utils/constants"
import { MainLayout } from "layouts/MainLayout"
import Login from "pages/accounts/Login"
import Register from "pages/accounts/Register"
import Device from "pages/orgs/Device"
import DeviceAlerts from "pages/orgs/Device/DeviceAlerts"
import DeviceEventsLog from "pages/orgs/Device/DeviceEventsLog"
import DeviceGroup from "pages/orgs/DeviceGroup"
import DeviceGroups from "pages/orgs/DeviceGroups"
import DevicePosition from "pages/orgs/Device/DevicePosition"
import DeviceSettings from "pages/orgs/Device/DeviceSettings"
import GroupAlerts from "pages/orgs/DeviceGroup/GroupAlerts"
import GroupEventsLog from "pages/orgs/DeviceGroup/GroupEventsLog"
import GroupInfo from "pages/orgs/DeviceGroup/GroupInfo"
import PlotsNew from "pages/orgs/PlotsNew"
import OrgSettings from "pages/orgs/OrgSettings"
import OrgHomepage from "pages/orgs"
import UserHomepage from "pages/users"
import CreateToken from "pages/users/CreateToken"
import UserHelp from "pages/users/UserHelp"
import Api from "pages/documentation/api"
import ApiDoc from "pages/documentation/api/ApiDoc"
import BoardLoader from "pages/BoardLoader"
import PasswordRecover from "pages/accounts/PasswordRecover"
import ActivateUser from "pages/accounts/ActivateUser"
import AlertsList from "pages/orgs/Alerts/AlertsList"
import EventsLog from "pages/orgs/Alerts/EventsLog"
import Alerts from "pages/orgs/Alerts"
import Preloader from "widgets/common/PreLoader"
import ProductDocs from "pages/documentation/product"

const DashboardLayout = lazy(() =>
  import("layouts").then((module) => ({
    default: module.DashboardLayout,
  })),
)
const RecoverPasswordLazy = lazy(() => import("pages/accounts/Recover"))
const ModifyPasswordLazy = lazy(() => import("pages/users/ModifyPassword"))
const QRPageLazy = lazy(() => import("pages/QRPage"))

export default function Routes() {
  const username = useSelector(selectCurrentUser)

  const location = useLocation()

  return (
    <RouterRoutes>
      <Route path="/accounts" element={<MainLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route
          path="recover"
          element={
            <Suspense fallback={<Preloader />}>
              <RecoverPasswordLazy />
            </Suspense>
          }
        />
        <Route path=":username/password-recover/:code" element={<PasswordRecover />} />
        <Route path=":username/activate/:secret" element={<ActivateUser />} />
      </Route>
      <Route
        path="/qr"
        element={
          <Suspense fallback={<Preloader />}>
            <QRPageLazy />
          </Suspense>
        }
      />
      <Route path="/users" element={<MainLayout />}>
        <Route
          path=":username/modify-password"
          element={
            <Suspense fallback={<Preloader />}>
              <ModifyPasswordLazy />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="/"
        element={
          username ? (
            <BoardLoader />
          ) : (
            <Navigate to="/accounts/login" state={{ redirectedFrom: DEFAULT_TARGET }} />
          )
        }
      />

      <Route
        element={
          username ? (
            <Suspense fallback={<Preloader />}>
              <DashboardLayout />
            </Suspense>
          ) : (
            <Navigate
              to="/accounts/login"
              state={{ redirectedFrom: location.pathname }}
            />
          )
        }
      >
        <Route path="/users/:username">
          <Route index element={<UserHomepage />} />
          <Route path="user-help" element={<UserHelp />} />
        </Route>

        <Route path="/orgs/:orgId">
          <Route index element={<OrgHomepage />} />
          <Route path="device-groups" element={<DeviceGroups />} />
          <Route path="device-groups/:id" element={<DeviceGroup />}>
            <Route index element={<GroupInfo />} />
            <Route path="alerts" element={<GroupAlerts />} />
            <Route path="events-log" element={<GroupEventsLog />} />
          </Route>
          <Route path="devices/:id" element={<Device />}>
            <Route index element={<MainDashboard />} />
            <Route path="position" element={<DevicePosition />} />
            <Route path="device-data" element={<DeviceData />} />
            <Route path="alerts" element={<DeviceAlerts />} />
            <Route path="events-log" element={<DeviceEventsLog />} />
            <Route path="settings" element={<DeviceSettings />} />
          </Route>
          <Route path="alerts" element={<Alerts />}>
            <Route index element={<AlertsList />} />
            <Route path="events-log" element={<EventsLog />} />
          </Route>
          {/* recharts plots tests/demo, not linked anywhere */}
          <Route path="plots-new" element={<PlotsNew />} />
          <Route path="org-settings" element={<OrgSettings />} />
        </Route>
        <Route path="/documentation">
          <Route path="api" element={<Api />}>
            <Route index element={<ApiDoc />} />
            <Route path="create-token" element={<CreateToken />} />
          </Route>
          <Route path="product" element={<ProductDocs />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </RouterRoutes>
  )
}
