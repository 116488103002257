import { Grid } from "@mui/material"

import type { DeviceType } from "types/device.types"

import LabelValueStack from "widgets/common/LabelValueStack"
import { formatDateFromMillis } from "helpers/utils/dateUtils"
import CardWithTitle from "widgets/common/CardWithTitle"

interface DeviceDetailsProps {
  device: DeviceType
}

const DeviceDetails = ({ device }: DeviceDetailsProps) => {
  return (
    <CardWithTitle titleKey={"device_settings.DEVICE_DETAILS"}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={12} xl={12}>
          <LabelValueStack labelKey={"device.DEVICE_ID"} value={device.less_id} />
          <LabelValueStack
            labelKey={"device_information.ACTIVATION_TIME"}
            value={formatDateFromMillis(device.activated_at)}
          />
          <LabelValueStack
            labelKey={"device_information.INSTALLED_AT"}
            value={formatDateFromMillis(device.settings?.installed_at)}
          />
          <LabelValueStack
            labelKey={"device_information.FIRMWARE_VERSION"}
            value={device?.firmware_version}
          />
          <LabelValueStack
            labelKey={"device_information.DEVICE_VERSION"}
            value={device.settings?.device_version ?? device?.hardware_version}
          />
        </Grid>
      </Grid>
    </CardWithTitle>
  )
}

export default DeviceDetails
