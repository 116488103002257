import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material"

import { CodeBox } from "widgets/styled/containers"
import CustomLink from "widgets/common/CustomLink"
import CardWithTitle from "widgets/common/CardWithTitle"
import { Paragraph, Subtitle } from "./wrappers"

interface ApiDocEnProps {
  url: string
}

export default function ApiDocEn({ url }: ApiDocEnProps) {
  return (
    <Stack maxWidth="xl" sx={{ pt: 2, gap: 2 }}>
      <CardWithTitle titleKey="api.AUTHENTICATION">
        <Subtitle>Token Authentication</Subtitle>
        <Paragraph>
          Token-Based Authentication, relies on a signed token that is sent to the server
          on each request.
        </Paragraph>
        <Paragraph>
          Keep in mind that the token grant access to make requests on behalf of an
          organization. These values should be considered as sensitive as passwords and
          must not be shared or distributed to untrusted parties.
        </Paragraph>

        <Subtitle>Creating the Token</Subtitle>
        <Paragraph>
          To access the api, a token is required. You can create a token in the
          <CustomLink href={"create-token"} component="span" underlineHover>
            {" "}
            &apos;API &rarr; Token &apos;{" "}
          </CustomLink>
          tab.
        </Paragraph>

        <Subtitle>Using the Token</Subtitle>
        <Paragraph>
          Every authenticated request must include an Authorization header with the token.
        </Paragraph>

        <Subtitle>cURL example:</Subtitle>
        <CodeBox component="pre">
          curl -H &apos;Authorization: Bearer MY_TOKEN&apos; -X GET{" "}
          {`"${url}/MY_REQUEST"`}
        </CodeBox>

        <Subtitle>Python example:</Subtitle>
        <CodeBox component="pre">
          {`import requests
response = requests.get('${url}/MY_REQUEST', headers={'Authorization': 'Bearer MY_TOKEN'})`}
        </CodeBox>
        <Subtitle>SSL Certificates</Subtitle>
        <Paragraph>
          All API requests will always be over HTTPS. The certificate used in the API
          server is <b>automatically renewed every 60 days</b>. Please take this into
          account when writing API clients.
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.RESPONSE">
        <Paragraph>
          See{" "}
          <CustomLink
            href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status"
            component="span"
            target="_blank"
            underlineHover
          >
            &apos;Responses Status&apos;
          </CustomLink>{" "}
          for a comprehensive list of status codes.
        </Paragraph>
        <Subtitle>Correct Responses</Subtitle>

        <Subtitle>200</Subtitle>
        <Paragraph>
          This is the http status that is returned when the request succeeded.
        </Paragraph>
        <Subtitle>Error Responses</Subtitle>
        <Subtitle>401 (Unauthorized)</Subtitle>
        <Paragraph>
          Authenticating with invalid credentials will return 401 Unauthorized:
        </Paragraph>
        <CodeBox component="pre">
          {`
{
  "message": "The server could not verify that you are authorized to access the URL requested.
  You either supplied the wrong credentials (e.g. a bad password), or your browser doesn't
  understand how to supply the credentials required."
}
        `}
        </CodeBox>

        <Subtitle>404 (Not Found)</Subtitle>
        <Paragraph>
          The url used does not exist. Check if there is a typo in the url.
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DATA_FORMAT">
        <Subtitle>Dates</Subtitle>
        <Paragraph>
          The dates returned by the api are the numbers of milliseconds that have elapsed
          since 00:00:00 Coordinated Universal Time (UTC), Thursday, 1 January 1970.
        </Paragraph>
        <Paragraph>
          The value returned has decimal fraction because it has the best accuracy given
          by the OS.
        </Paragraph>
        <Paragraph>
          A useful page to work with this kind of dates is:
          <CustomLink
            href="http://www.epochconverter.com/"
            component="span"
            target="_blank"
            underlineHover
          >
            {" "}
            http://www.epochconverter.com/
          </CustomLink>
          .
        </Paragraph>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_API">
        <Subtitle>Resource URL</Subtitle>
        <Paragraph>{`${url}/v1/device/`}</Paragraph>

        <Subtitle>Resource Description</Subtitle>
        <Paragraph>
          Returns a collection of all available devices. This endpoint{" "}
          <b>cannot be used</b> by users of type <i>reseller</i>.
        </Paragraph>

        <Subtitle>Resource Information</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Method:</b> GET
            </li>
            <li>
              <b>Response formats:</b> JSON
            </li>
            <li>
              <b>Requires authentication?</b> Yes
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parameters</Subtitle>
        <Paragraph>This operation does not have parameters.</Paragraph>

        <Subtitle>Example Request</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/devices/`}</Paragraph>

        <Subtitle>Example Response</Subtitle>
        <CodeBox component="pre">
          {`
[
    {
      "device_type": "sentrisense",
      "activated_at": 1674744569600.811,
      "less_id": 12345,
      "name": "device A",
      ...
    },
    {
      "device_type": "weather_station",
      "activated_at": 1674746789101.34,
      "less_id": 67890,
      "name": "device B",
      ...
    },
    ...
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_GROUPS_API">
        <Subtitle>Resource URL</Subtitle>
        <Paragraph>{`${url}/v1/device_groups/`}</Paragraph>

        <Subtitle>Resource Description</Subtitle>
        <Paragraph>
          Returns a collection of device groups. This endpoint <b>cannot be used</b> by
          users of type <i>reseller</i>.
        </Paragraph>

        <Subtitle>Resource Information</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Method:</b> GET
            </li>
            <li>
              <b>Response formats:</b> JSON
            </li>
            <li>
              <b>Requires authentication?</b> Yes
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parameters</Subtitle>
        <Paragraph>This operation does not have parameters.</Paragraph>

        <Subtitle>Example Request</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/device_groups/`}</Paragraph>

        <Subtitle>Example Response</Subtitle>
        <CodeBox component="pre">
          {`
[
  {
    "device_list": [],
    "name": "Group number one"
    ...
  },
  {
    "device_list": [
      {
        "device_type": "sentrisense",
        "activated_at": 1674744569600.811,
        "less_id": 12345,
        "name": "device A",
        ...
      }
    ],
    "name": "Group number two",
    ...
  },
  {
    "device_list": [
      {
        "device_type": "sentrisense",
        "activated_at": 1674744569600.811,
        "less_id": 12345,
        "name": "device A",
        ...
      },
      {
        "device_type": "weather_station",
        "activated_at": 1674746789101.34,
        "less_id": 67890,
        "name": "device B",
        ...
      }
    ],
    "name": "Default Group",
    ...
  }
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.TELEMETRY_API">
        <Subtitle>Resource URL</Subtitle>
        <Paragraph>{`${url}/v1/devices/$HARDWARE_ID/telemetry/`}</Paragraph>

        <Subtitle>Resource Description</Subtitle>
        <Paragraph>
          Returns a collection of telemetries as reported by the given device.
        </Paragraph>

        <Subtitle>Resource Information</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Method:</b> GET
            </li>
            <li>
              <b>Response formats:</b> CSV or JSON
            </li>
            <li>
              <b>Requires authentication?</b> Yes
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parameters</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  hardware_id
                </TableCell>
                <TableCell>
                  The identifier for the device. This parameter is part of the resource
                  url.
                </TableCell>
                <TableCell>Example value: 12345</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  from_date
                </TableCell>
                <TableCell>
                  Select telemetries reported after the specified date time. ISO 8601 date
                  format. Passing no value takes the to_date parameter and moves back 1
                  day as default.
                </TableCell>
                <TableCell>Example value: &apos;2015-12-27T03:00:58.562Z&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  to_date
                </TableCell>
                <TableCell>
                  Select telemetries reported before the specified date time. ISO 8601
                  date format. Passing no value takes the current time as default.
                </TableCell>
                <TableCell>Example value: &apos;2015-12-27T03:00:58.562Z&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  limit
                </TableCell>
                <TableCell>
                  The maximum of number telemetry rows per field returned. Defaults to 0
                  (no limit).
                </TableCell>
                <TableCell>Example value: 20</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  format
                </TableCell>
                <TableCell>
                  Select between a <em>csv</em> or <em>json</em> format.
                </TableCell>
                <TableCell>Example value: &apos;json&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field
                </TableCell>
                <TableCell>
                  Select the telemetries containing the specified field. This can be
                  specified multiple times to select multiple fields.
                </TableCell>
                <TableCell>Example value: &apos;power.battery.level&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field_prefix
                </TableCell>
                <TableCell>
                  Select the telemetries containing fields that start with the specified
                  prefix.
                </TableCell>
                <TableCell>Example value: &apos;power.battery.&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  field_pivot
                </TableCell>
                <TableCell>
                  Use the field names as columns in the result. Defaults to false.
                </TableCell>
                <TableCell>Example value: 1</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  only_field_names
                </TableCell>
                <TableCell>
                  Ignore the values and keep only the field names. Defaults to false.
                </TableCell>
                <TableCell>Example value: 1</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Subtitle>Example Request</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>
          {`${url}/v1/devices/12345/telemetry/?from_date=2024-05-12T00:00:00.000Z&to_date=2024-05-14T00:00:00.000Z&limit=200&format=json`}
        </Paragraph>

        <Subtitle>Example Response</Subtitle>
        <CodeBox component="pre">
          {`
[
   {
       "result": "telemetry",
       "table": 0,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T15:13:46.837481+00:00",
       "_value": "2024.02.06.d7bf307",
       "_field": "firmware_version",
       "id": "12345",
       "ingestor": "catapult"
   },
   {
       "result": "telemetry",
       "table": 1,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T23:40:44.999542+00:00",
       "_value": 1.9573431193818354,
       "_field": "sensors.motion.aeolian.transverse_wind_speed_estimate",
       "id": "12345",
       "ingestor": "aeolian.20231115"
   },
   {
       "result": "telemetry",
       "table": 15,
       "_start": "2024-05-12T00:00:00+00:00",
       "_stop": "2024-05-14T00:00:00+00:00",
       "_time": "2024-05-13T21:09:36.274419+00:00",
       "_value": 88.5,
       "_field": "power.battery.level",
       "id": "12345",
       "ingestor": "catapult"
   },
   ...
]
            `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.DEVICE_GEO_API">
        <Subtitle>Resource URL</Subtitle>
        <Paragraph>{`${url}/v1/gps_locations/`}</Paragraph>

        <Subtitle>Resource Description</Subtitle>
        <Paragraph>Returns the last known location of a device.</Paragraph>

        <Subtitle>Resource Information</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Method:</b> GET
            </li>
            <li>
              <b>Response formats:</b> JSON
            </li>
            <li>
              <b>Requires authentication?</b> Yes
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parameters</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  less_id (required)
                </TableCell>
                <TableCell>The identifier for the device.</TableCell>
                <TableCell>Example value: 12345</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Subtitle>Example Request</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>{`${url}/v1/gps_locations/?less_id=12345`}</Paragraph>
        <Subtitle>Example Response</Subtitle>
        <CodeBox component="pre">
          {`
[
  {
    'result': 'location',
    'table': 0,
    '_time': '2023-08-12T12:51:51.920484+00:00',
    'id': '12345',
    'lat': -40.3465719758353,
    'lon': 175.70627619875916
  }
]
              `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.ALERTS_API">
        <Subtitle>Resource URL</Subtitle>
        <Paragraph>{`${url}/v1/alerts/`}</Paragraph>

        <Subtitle>Resource Description</Subtitle>
        <Paragraph>Returns a collection of alerts.</Paragraph>

        <Subtitle>Resource Information</Subtitle>
        <Paragraph>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Method:</b> GET
            </li>
            <li>
              <b>Response formats:</b> JSON
            </li>
            <li>
              <b>Requires authentication?</b> Yes
            </li>
          </ul>
        </Paragraph>

        <Subtitle>Parameters</Subtitle>
        <TableContainer
          component={Paper}
          sx={{ py: 1, "&.MuiPaper-root": { boxShadow: "none" } }}
        >
          <Table
            sx={{
              minWidth: 650,
              border: (theme) => "1px solid " + theme.palette.grey[300],
              borderCollapse: "unset",
              boxShadow: "none",
            }}
            size="small"
          >
            <TableBody>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  less_id (optional)
                </TableCell>
                <TableCell>The identifier for the device.</TableCell>
                <TableCell>Example value: 12345</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  from_date
                </TableCell>
                <TableCell>
                  Returns messages created after the specified <em>from date</em>. ISO
                  8601 date format.
                </TableCell>
                <TableCell>Example value: &apos;2015-12-27T03:00:58.562Z&apos;</TableCell>
              </TableRow>
              <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
                  to_date
                </TableCell>
                <TableCell>
                  Returns messages created before the specified <em>to date</em>. ISO 8601
                  date format.
                </TableCell>
                <TableCell>Example value: &apos;2015-12-27T03:00:58.562Z&apos;</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Subtitle>Example Request</Subtitle>
        <Paragraph>GET</Paragraph>
        <Paragraph>
          {`${url}/v1/alerts/?from_date=2024-05-01T00:00:00.000Z&to_date=2024-05-14T00:00:00.000Z&limit=10&less_id=12345`}
        </Paragraph>

        <Subtitle>Example Response</Subtitle>
        <CodeBox component="pre">
          {`
{
  "results": [
    {
      'alert_setting_id': 2345678901234,
      'type': 'sentrisense_motion',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-05-07 06:55:19 GMT: Alert 'Motion' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1715064919519.014,
      'alert_name': 'Motion',
      'enterprise': 1,
      'id': 34567890
    },
    {
      'alert_setting_id': 72363857920,
      'type': 'threshold',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-04-30 05:57:22 GMT: Alert 'Low Battery' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1714456642977.832,
      'alert_name': 'Low Battery',
      'enterprise': 1,
      'id': 2908490752,
    },
    {
      'type': 'threshold',
      'notifications': 1,
      'summary': "2024-04-30 00:59:04 GMT: Alert 'Low Battery' on the device 'device A'",
      'device_name': 'device A',
      'sent_by_email': True,
      'alert_setting_id': 72363857920,
      'status': 11,
      'enterprise': 1,
      'less_id': 12345,
      'resolved': False,
      'viewed': False,
      'triggered': True,
      'alert_name': 'Low Battery',
      'timestamp': 1714438744589.686,
      'id': 537513984,
    },
    {
      'alert_setting_id': 1234567890123,
      'type': 'sentrisense_motion',
      'resolved': False,
      'notifications': 1,
      'summary': "2024-05-10 21:51:50 GMT: Alert 'Motion' on the device 'device A'",
      'device_name': 'device A',
      'viewed': True,
      'triggered': True,
      'status': 8,
      'less_id': 12345,
      'timestamp': 1715377910.252,
      'alert_name': 'Motion',
      'enterprise': 1,
      'id': 45678901
    },
  ...
  ],
  "limit": 10,
  "next": "Ck8KFgoJdGltZXN0YW1wEgkhPf6s9631eEISMWoOc35sZXNzLWJhY2tlbmRyEgsSBUFsZXJ0GICAxLmD24UIDKIBCnByb2R1Y3Rpb24YACAB"
]
        `}
        </CodeBox>
      </CardWithTitle>
      <CardWithTitle titleKey="api.EXTRA_DOCUMENTATION">
        <Paragraph>
          Access{" "}
          <CustomLink
            href="https://colab.research.google.com/drive/1I5qn8JluLtAPrN1BtLkoa3sG0DtiTXXW?usp=sharing"
            component="span"
            underlineHover
            target="_blank"
          >
            here
          </CustomLink>{" "}
          to an interactive Python notebook file with additional examples for
          experimenting with pre-made queries.
        </Paragraph>
      </CardWithTitle>
    </Stack>
  )
}
