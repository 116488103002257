import type { FC } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { useTheme } from "@mui/system"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { FormProvider, useForm } from "react-hook-form"

import { selectCurrentOrgId } from "features/store/orgSlice"
import { useCreateUserMutation } from "features/api"
import { getBrand } from "helpers/utils/common"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"

import ControlledInput from "../common/ControlledInput"
import Spinner from "../common/Spinner"
import { SecondaryButton } from "../styled/buttons"

interface ICreateUserPopupProps {
  open: boolean
  onClose: () => void
}

interface ICreateUserForm {
  username: string
}

const CreateUserPopup: FC<ICreateUserPopupProps> = ({ onClose, open }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const orgId = useSelector(selectCurrentOrgId)
  const methods = useForm<ICreateUserForm>({
    mode: "all",
    defaultValues: {
      username: "",
    },
  })
  const [createUser, { isLoading: createUserLoading, isSuccess }] =
    useCreateUserMutation()

  const onSubmit = ({ username }: ICreateUserForm) => {
    const brand = getBrand(window.location.href)
    snackbarMutation({
      mutation: createUser({ username, orgId: orgId as number, brand }).unwrap(),
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", { item: t("generic.USER") }),
      ),
      getSuccessMessage: () => t("users.USER_CREATED"),
    })
      .then(() => {
        onClose()
      })
      .catch()
  }

  return (
    <>
      <Dialog open={open} scroll={"paper"} onClose={onClose} maxWidth="xl">
        <DialogTitle>{t("enterprise.CREATE_USER")}</DialogTitle>
        {createUserLoading && (
          <Box
            sx={{
              minWidth: "500px",
              minHeight: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </Box>
        )}
        {!createUserLoading && !isSuccess && (
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <DialogContent dividers={true}>
                <ControlledInput
                  autoFocus
                  name="username"
                  placeholder={t("users.ENTER_USER_EMAIL")}
                  label={t("generic.USERNAME")}
                  styles={{
                    "& .MuiInput-root": {
                      fontSize: "1rem",
                    },
                    [theme.breakpoints.up("md")]: {
                      minWidth: "300px",
                    },
                  }}
                />
              </DialogContent>
              <DialogActions sx={{ justifyContent: "flex-end" }}>
                <SecondaryButton onClick={onClose}>{t("generic.CANCEL")}</SecondaryButton>
                <Button sx={{ width: "fit-content" }} type="submit">
                  {t("generic.CREATE")}
                </Button>
              </DialogActions>
            </FormProvider>
          </form>
        )}
      </Dialog>
    </>
  )
}

export default CreateUserPopup
