import { styled } from "@mui/material/styles"
import { Popup as LPopup, Tooltip } from "react-leaflet"

import { getAlertStateColor } from "../../helpers/utils/styles"

export const MarkerTooltip = styled(Tooltip, {
  shouldForwardProp: (prop) => prop !== "alertState",
})<{ alertState: string }>(({ theme, alertState }) => ({
  border: "none",
  boxShadow: "none",
  borderRadius: "none",
  fontWeight: 600,
  backgroundColor: "#fff",
  color: getAlertStateColor(alertState, theme),
  "&:before": {
    opacity: 0,
  },
}))

export const Popup = styled(LPopup)(() => ({
  borderRadius: 0,
  padding: 0,
  minWidth: "250px",
  "& .leaflet-popup-content-wrapper": {
    borderRadius: "5px",
    padding: "1rem 1rem 0.5rem 1rem",
  },
  "& .leaflet-popup-tip-container": {
    visibility: "hidden",
  },
  "& .leaflet-popup-content": {
    margin: 0,
    "& p": {
      margin: "0.5rem 0 0 0",
    },
  },
}))
