import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { devicesStatusPlotDef } from "helpers/config/plots/pieDefs"
import useDevices from "helpers/hooks/useDevices"
import { getDevicesByStatus } from "helpers/utils/devices"
import { SectionCard } from "widgets/styled/containers"
import Message from "widgets/common/Message"
import Plot from "widgets/plots/Plot"
import PieChartSkeleton from "./PieChartSkeleton"

const DevicesStatusPieChart = () => {
  const { t } = useTranslation()
  const { devsWRelsById, isLoading, isError } = useDevices()

  const devicesByStatus = useMemo(
    () => getDevicesByStatus(devsWRelsById),
    [devsWRelsById],
  )

  return (
    <SectionCard styles={{ margin: 0, border: "none" }}>
      {isLoading ? (
        <PieChartSkeleton />
      ) : isError ? (
        <Message
          messageKey={"error.FETCHING_ITEM"}
          messageOptions={{ item: t("alerts.CHART"), context: "male", count: 1 }}
          type="error"
        />
      ) : (
        <Plot view={devicesStatusPlotDef.view} data={devicesByStatus} />
      )}
    </SectionCard>
  )
}

export default DevicesStatusPieChart
