import type { PayloadAction, Reducer } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { DateRange } from "types/date.types"
import type { RootState } from "."

interface DateRangeState {
  deviceDateRange: DateRange | null
  eventsDateRange: DateRange | null
}

const initialState: DateRangeState = {
  deviceDateRange: null,
  eventsDateRange: null,
}

export const dateRangeSlice = createSlice({
  name: "dateRange",
  initialState,
  reducers: {
    updateDeviceDateRange: (state, action: PayloadAction<DateRange>): DateRangeState => ({
      ...state,
      deviceDateRange: action.payload,
    }),
    updateEventsDateRange: (state, action: PayloadAction<DateRange>): DateRangeState => ({
      ...state,
      eventsDateRange: action.payload,
    }),
  },
})

export const { updateDeviceDateRange, updateEventsDateRange } = dateRangeSlice.actions
export const dateRangeReducer: Reducer<DateRangeState> = dateRangeSlice.reducer
export default dateRangeReducer

export const selectDeviceDateRange = (state: RootState) => state.dateRange.deviceDateRange
export const selectEventsDateRange = (state: RootState) => state.dateRange.eventsDateRange
