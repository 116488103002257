import { zxcvbnOptions } from "@zxcvbn-ts/core"

export const loadLanguagePackage = async (language: string) => {
  if (language.includes("es")) {
    return await import("@zxcvbn-ts/language-es-es")
  } else {
    return await import("@zxcvbn-ts/language-en")
  }
}

export const setZxcvbnOptions = async (language: string) => {
  const zxcvbnCommonPackage = await import("@zxcvbn-ts/language-common")
  const zxcvbnLanguagePackage = await loadLanguagePackage(language)

  const options = {
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnLanguagePackage.dictionary,
    },
    translations: zxcvbnLanguagePackage.translations,
  }
  return zxcvbnOptions.setOptions(options)
}
