import { useMemo, useState, useContext } from "react"
import * as PropTypes from "prop-types"
import { MapContainer, ScaleControl, TileLayer, useMapEvents } from "react-leaflet"
import { DateTime } from "luxon"
import fp from "lodash/fp"
import "leaflet/dist/leaflet.css"

import DashboardContext from "widgets/device/DeviceDashboard/DashboardContext"
import useDevices from "helpers/hooks/useDevices"
import Spinner from "widgets/common/Spinner"
import { getCellHeight } from "helpers/utils/dashboards"
import { OSM_TILE_SERVER_CONFIG } from "features/config"
import DeviceMapMarkers from "./DeviceMapMarkers"

const TOOLTIP_THRESHOLD = 13

function showTooltips(mapLayer, shouldShow) {
  mapLayer?.eachLayer((layer) => {
    if (layer.getTooltip()) {
      shouldShow ? layer.openTooltip() : layer.closeTooltip()
    }
  })
}

function MapEvents({ initialZoomLevel }) {
  const [lastZoom, setLastZoom] = useState(initialZoomLevel)
  const mapLayer = useMapEvents({
    zoomend: () => {
      const zoom = mapLayer.getZoom()
      const shouldShow = zoom >= TOOLTIP_THRESHOLD
      const wasShown = lastZoom >= TOOLTIP_THRESHOLD
      if (shouldShow !== wasShown) {
        showTooltips(mapLayer, shouldShow)
      }
      setLastZoom(zoom)
    },
  })
  return null
}

const getLatLonData = (data) => {
  const formattedTelemetryData = fp.flow(
    fp.filter(({ _field }) => _field === "lat" || _field === "lon"),
    fp.groupBy("id"),
    fp.toPairs,
    fp.map(([_, value]) => {
      let joinedData = { _field: "latlon", name: "Location" }
      fp.each((obj) => {
        const { _field, _value, name, ...rest } = obj
        if (_field === "lat") {
          joinedData = { ...joinedData, ...rest, lat: _value }
        } else if (_field === "lon") {
          joinedData = { ...joinedData, ...rest, lon: _value }
        }
      }, value)
      return [_, joinedData]
    }),
    fp.fromPairs,
    fp.values,
  )(data)

  const allData = [...data, ...formattedTelemetryData]
  const filteredLatLonData = allData?.filter((obj) => obj.lat && obj.lon)

  return filteredLatLonData
}

export default function GeoPlot({ data }) {
  const dashboardContext = useContext(DashboardContext)
  const { devsWRelsByIdWStates } = useDevices()

  const latlonData = useMemo(() => getLatLonData(data), [data])

  const initialZoomLevel = 9

  const formattedDevicesData = useMemo(() => {
    return latlonData?.map((obj) => {
      const { groups, settingsStates, device } = devsWRelsByIdWStates?.[obj.id] || {}
      const extras = fp.omitBy(fp.isNil, {
        name: device?.name,
        type: device?.device_type,
        groups,
        settingsStates,
      })
      return {
        ...extras,
        ...obj,
        _time: DateTime.fromISO(obj._time).toJSDate(),
      }
    })
  }, [devsWRelsByIdWStates, latlonData])

  const center = useMemo(
    () =>
      formattedDevicesData[0]
        ? [formattedDevicesData[0].lat, formattedDevicesData[0].lon]
        : [0, 0],
    [formattedDevicesData],
  )

  const mapHeight = useMemo(
    () =>
      dashboardContext?.config?.type === "span"
        ? `calc(${
            getCellHeight("LOCATION", dashboardContext.config.type) *
            dashboardContext.rowHeight *
            0.9
          }px)`
        : "inherit",
    [dashboardContext],
  )

  return (
    <>
      {!formattedDevicesData ? (
        <Spinner />
      ) : (
        <MapContainer
          center={center}
          zoom={initialZoomLevel}
          scrollWheelZoom={true}
          style={{ height: mapHeight }}
          whenCreated={(mapInstance) => {
            const zoom = mapInstance.getZoom()
            showTooltips(mapInstance, zoom >= TOOLTIP_THRESHOLD)
          }}
        >
          <MapEvents initialZoomLevel={initialZoomLevel} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={OSM_TILE_SERVER_CONFIG}
          />
          <DeviceMapMarkers devices={formattedDevicesData} />
          <ScaleControl position="bottomleft" />
        </MapContainer>
      )}
    </>
  )
}

GeoPlot.propTypes = {
  data: PropTypes.array,
}

MapEvents.propTypes = {
  initialZoomLevel: PropTypes.number,
}
