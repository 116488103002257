import { useMemo, useState } from "react"
import type { FC } from "react"
import { Grid, IconButton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import EditIcon from "@mui/icons-material/Edit"

import type { OrgType } from "types/orgs.types"

import usePermissions from "helpers/hooks/usePermissions"
import { SectionCard, SectionCardHeader } from "../styled/containers"
import Spinner from "../common/Spinner"
import NotificationSettingsForm from "./NotificationSettingsForm"
import TelephoneList from "./TelephoneList"
import EmailList from "./EmailList"

interface NotificationSettingsProps {
  org: OrgType
  isFetching: boolean
}

const NotificationSettings: FC<NotificationSettingsProps> = ({ org, isFetching }) => {
  const { isEditor } = usePermissions()
  const [isEditing, setIsEditing] = useState(false)
  const { t } = useTranslation()
  const isEditAllowed = useMemo(() => isEditor && !isEditing, [isEditor, isEditing])

  return (
    <SectionCard>
      <SectionCardHeader sx={{ justifyContent: "space-between" }}>
        <Typography component="h5" variant="h5">
          {t("device_settings.NOTIFICATION_SETTINGS")}
        </Typography>
        {isEditAllowed && (
          <IconButton
            color="primary"
            aria-label="edit-field"
            onClick={() => setIsEditing(true)}
            sx={{ p: 0 }}
          >
            <EditIcon sx={{ fontSize: "inherit" }} />
          </IconButton>
        )}
      </SectionCardHeader>
      {isFetching ? (
        <Spinner />
      ) : isEditing ? (
        <NotificationSettingsForm
          org={org}
          onClose={() => {
            setIsEditing(false)
          }}
        />
      ) : (
        <Grid container sx={{ p: 3 }}>
          <Grid item xs={12} sm={6}>
            <TelephoneList telephones={org?.telephone_list || []} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <EmailList emails={org?.email_list || []} />
          </Grid>
        </Grid>
      )}
    </SectionCard>
  )
}

export default NotificationSettings
