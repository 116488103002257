import { useMemo } from "react"
import { useSelector } from "react-redux"

import { selectCurrentOrgRole } from "features/store/orgSlice"
import { adminCheckByRole, editCheckByRole, ownerCheckByRole } from "helpers/utils/user"

const usePermissions = () => {
  const userRole = useSelector(selectCurrentOrgRole)

  const isOwner = useMemo(() => ownerCheckByRole(userRole), [userRole])
  const isAdmin = useMemo(() => adminCheckByRole(userRole), [userRole])
  const isEditor = useMemo(() => editCheckByRole(userRole), [userRole])

  return { isOwner, isAdmin, isEditor }
}

export default usePermissions
