import { Link } from "react-router-dom"
import { Close } from "@mui/icons-material"
import { Button, IconButton, Stack } from "@mui/material"
import type { SnackbarKey } from "notistack"
import { enqueueSnackbar, closeSnackbar } from "notistack"

import { ALERT_SNACKBAR_TIME } from "helpers/utils/constants"

const close = (snackbarId: string | number) => (
  <IconButton
    onClick={() => {
      closeSnackbar(snackbarId)
    }}
  >
    <Close />
  </IconButton>
)

const alertLink = (href: string, buttonText: string) => {
  const inner = (snackbarId: string | number) => (
    <Link to={href}>
      <Button
        size="small"
        sx={{ whiteSpace: "nowrap" }}
        onClick={() => closeSnackbar(snackbarId)}
      >
        {buttonText}
      </Button>
    </Link>
  )
  inner.displayName = "alertLink"
  return inner
}

const actionAndClose = (
  snackbarId: string | number,
  component: (snackbarId: string | number) => JSX.Element,
) => (
  <Stack gap={1} direction={"row"} alignItems={"center"}>
    {component(snackbarId)}
    {close(snackbarId)}
  </Stack>
)

export const errorMessage = (text: string): SnackbarKey =>
  enqueueSnackbar(text, { variant: "error", action: close })

export const warningMessage = (text: string): SnackbarKey =>
  enqueueSnackbar(text, { variant: "warning", action: close })

export const successMessage = (text: string): SnackbarKey =>
  enqueueSnackbar(text, { variant: "success", action: close })

export const infoMessage = (text: string): SnackbarKey =>
  enqueueSnackbar(text, { variant: "info", action: close })

export const triggeredAlert = (
  text: string,
  href: string,
  buttonText: string,
): SnackbarKey => {
  const component = alertLink(href, buttonText)
  return enqueueSnackbar(text, {
    variant: "triggered",
    action: (key) => actionAndClose(key, component),
    autoHideDuration: ALERT_SNACKBAR_TIME,
  })
}

export const renotificationAlert = (
  text: string,
  href: string,
  buttonText: string,
): SnackbarKey => {
  const component = alertLink(href, buttonText)
  return enqueueSnackbar(text, {
    variant: "renotification",
    action: (key) => actionAndClose(key, component),
    autoHideDuration: ALERT_SNACKBAR_TIME,
  })
}
