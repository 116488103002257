import { useEffect, useMemo } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import fp from "lodash/fp"
import { Stack, useTheme } from "@mui/material"

import { useDeviceQuery } from "features/api"
import PreviousPage from "widgets/common/PreviousPage"
import Spinner from "widgets/common/Spinner"
import DeviceSummary from "widgets/device/DeviceSummary"
import useDevices from "helpers/hooks/useDevices"
import { DEFAULT_TARGET } from "helpers/utils/constants"
import DashboardDatePicker from "widgets/device/DeviceDashboard/DashboardDatePicker"
import ScrollToTopButton from "widgets/common/ScrollToTopButton"
import { getDashboardTabsConfig } from "helpers/config/tabsConfigs"
import useDateRange from "helpers/hooks/useDateRange"
import LinkTabsSection from "widgets/common/LinkTabsSection"

function Device() {
  const theme = useTheme()
  const { deviceSearchParams } = useDateRange()
  const { devsWRelsById } = useDevices()
  const navigate = useNavigate()
  const { orgId, id } = useParams()
  const {
    data: device,
    isSuccess: deviceIsSuccess,
    isLoading: deviceIsLoading,
    isError,
  } = useDeviceQuery({ id: Number(id) })

  const groups = useMemo(
    () => fp.getOr([], [Number(id), "groups"], devsWRelsById),
    [devsWRelsById, id],
  )
  const isWrongOrg = useMemo(
    () => device && device.enterprise_id !== Number(orgId),
    [device, orgId],
  )
  const dashboardTabsConfig = useMemo(
    () => device && getDashboardTabsConfig(deviceSearchParams, device.device_type),
    [device, deviceSearchParams],
  )

  useEffect(() => {
    if (isError) {
      navigate(DEFAULT_TARGET)
    } else if (isWrongOrg && device) {
      const url = `/orgs/${device.enterprise_id}/devices/${device.less_id}`
      navigate(url)
    } else if (isWrongOrg) {
      navigate(DEFAULT_TARGET)
    }
  }, [isError, isWrongOrg, navigate, device])

  return (
    <>
      {deviceIsLoading || isWrongOrg ? (
        <Spinner />
      ) : (
        deviceIsSuccess && (
          <>
            <PreviousPage />
            <Stack
              flexDirection="row"
              alignItems="start"
              justifyContent="space-between"
              sx={{ [theme.breakpoints.down("md")]: { flexDirection: "column" } }}
            >
              <DeviceSummary device={device} groups={groups} />
              <DashboardDatePicker />
            </Stack>
            {dashboardTabsConfig && <LinkTabsSection tabsConfig={dashboardTabsConfig} />}
            <Outlet context={{ id, device }} />
            <ScrollToTopButton />
          </>
        )
      )}
    </>
  )
}

export default Device
