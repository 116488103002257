import { Box } from "@mui/material"
import fp from "lodash/fp"
import { useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

import { useDeviceTelemetryQuery } from "features/api"
import { positionPlotView as view } from "helpers/config/plots"
import { getRecordService } from "helpers/formatters/dataFormatters"
import { GRID_LAYOUT_GAP, SENTRISENSE_POSITION_TIME } from "helpers/utils/constants"
import type { DeviceTelemetryType } from "types/telemetries.types"
import DashboardContext from "widgets/device/DeviceDashboard/DashboardContext"
import DevicePositionPlot from "widgets/device/DevicePositionPlot"
import type { DevicePositionPlotValues } from "widgets/device/DevicePositionPlot"
import Plot from "widgets/plots/Plot"
import useDateRange from "helpers/hooks/useDateRange"

const DevicePosition = () => {
  const { deviceId, rowHeight, dispatchPlotState } = useContext(DashboardContext)
  const { deviceDateRange, isDeviceDateRangeValid } = useDateRange()
  const { t } = useTranslation()

  const { data, isFetching } = useDeviceTelemetryQuery(
    {
      id: deviceId,
      params: {
        aggregate: ["last"],
        aggregate_n: 360,
        field: ["sensors.orientation.abg.alpha", "sensors.orientation.abg.beta"],
        group: ["id", "_field"],
        from_date: deviceDateRange?.fromDate,
        to_date: deviceDateRange?.toDate,
      },
    },
    {
      skip: !isDeviceDateRangeValid,
      pollingInterval: SENTRISENSE_POSITION_TIME,
    },
  )

  const latestData: DevicePositionPlotValues = useMemo(() => getLatestData(data), [data])
  const recordService = useMemo(() => getRecordService(view.properties.fill, t), [t])

  useEffect(() => {
    dispatchPlotState({
      type: "UPDATE_CELL_STATE",
      payload: {
        id: view.nameKey,
        isFetching,
      },
    })
  }, [isFetching, dispatchPlotState])

  if (!data) {
    return <></>
  }

  return (
    <>
      {latestData && Number.isFinite(latestData._time) && (
        <Box height={"100%"} display={"flex"} flexDirection={"column"}>
          <DevicePositionPlot values={latestData} />
          <Box height={rowHeight * 3} marginTop={`${GRID_LAYOUT_GAP}px`}>
            <Plot view={view} data={data} recordService={recordService} />
          </Box>
        </Box>
      )}
    </>
  )
}

const getLatestData = (
  data: DeviceTelemetryType[] | undefined,
): DevicePositionPlotValues => {
  type MaybePartialValues = Partial<DevicePositionPlotValues> | null

  // Pivot data and convert time to number
  type _Mangle = (
    o: DeviceTelemetryType,
    valueKey: string,
  ) => Partial<DevicePositionPlotValues>
  const _mangle: _Mangle = (o, valueKey) => ({
    ...o,
    [valueKey]: o._value,
    _time: toMillis(o._time),
  })

  // Latest for field, mangle the result to form the position plot values
  type _LatestPivot = (
    field: string,
    pivotKey: string,
    byField: Record<string, DeviceTelemetryType[]>,
  ) => MaybePartialValues
  const _latestPivot: _LatestPivot = (field, pivotKey, byField) => {
    const telemetries = fp.getOr([], field, byField)
    const latest = fp.maxBy(getTime, telemetries)
    return latest ? _mangle(latest, pivotKey) : null
  }

  // Merge the partial values, use max time
  type _Merge = (
    sources: MaybePartialValues[],
    fallback: DevicePositionPlotValues,
  ) => DevicePositionPlotValues
  const _merge: _Merge = (sources, fallback) => {
    return fp.mergeAllWith(
      (a, b, key) => {
        if (key === "_time") return fp.max([a, b])
      },
      [fallback, ...fp.filter((o) => Boolean(o), sources)],
    )
  }

  const byField = fp.groupBy((o: DeviceTelemetryType) => o._field, data)

  const latestAlpha = _latestPivot("sensors.orientation.abg.alpha", "alpha", byField)
  const latestBeta = _latestPivot("sensors.orientation.abg.beta", "beta", byField)

  // The position plot doesn't deal with missing data nicely, so add a fallback
  const fallback: DevicePositionPlotValues = {
    _time: Number.NEGATIVE_INFINITY,
    alpha: 0.0,
    beta: 0.0,
  }

  return _merge([latestAlpha, latestBeta], fallback)
}

const toMillis = (date: string) => new Date(date).getTime()
const getTime = (o: DeviceTelemetryType) => toMillis(o._time)

export default DevicePosition
