import fp from "lodash/fp"

import type { IPlotDef } from "types/dashboard.types"

import { gaugeView } from "./base"

const batteryStatusPlotView = fp.merge(gaugeView, {
  name: "Battery Status",
  nameKey: "BATTERY_STATUS",
  properties: {
    footerFields: ["_time"],
    ticksValueInterval: 5,
    suffix: "%",
    tickSuffix: "%",
  },
})

export const batteryStatusPlotDef: IPlotDef = {
  view: batteryStatusPlotView,
  draggable: true,
  reqConfig: {
    fields: ["power.battery.level", "power.battery.status"],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}

const signalStatusPlotView = fp.merge(gaugeView, {
  name: "Signal level [dBm]",
  nameKey: "SIGNAL_LEVEL",
  properties: {
    footerFields: ["_time"],
    ticksValueInterval: 3,
    suffix: "dBm",
    colors: [
      { id: "0", type: "min", value: -121 },
      { id: "1", type: "max", value: -35 },
    ],
  },
})

export const signalStatusPlotDef: IPlotDef = {
  view: signalStatusPlotView,
  draggable: true,
  reqConfig: {
    fields: ["net.signal", "net.transport", "net.name"],
    field_pivot: false,
    method: ["last"],
    group: ["id", "_field"],
  },
}
