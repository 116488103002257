import { useMemo } from "react"
import { useSelector } from "react-redux"

import { useLastLocationsQuery } from "features/api"
import { formatLocationData } from "helpers/formatters/dataFormatters"
import { selectCurrentOrgId } from "features/store/orgSlice"
import Map, { SectionCardContainer } from "widgets/common/Map"
import { mapView } from "helpers/config/plots"

const MapSection = () => {
  const orgId = useSelector(selectCurrentOrgId)
  const {
    data: locationData,
    isFetching: locationsFetching,
    isSuccess: locationsSuccess,
  } = useLastLocationsQuery(
    { params: { org_id: orgId as number } },
    { skip: !orgId, refetchOnMountOrArgChange: true },
  )

  const flattenLocationData = useMemo(
    () => formatLocationData(locationData),
    [locationData],
  )

  return (
    <Map
      isPending={locationsFetching}
      isSuccess={locationsSuccess}
      data={flattenLocationData}
      view={mapView}
      container={SectionCardContainer}
    />
  )
}

export default MapSection
