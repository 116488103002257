import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { selectCurrentOrgId } from "features/store/orgSlice"
import useOrgs from "helpers/hooks/useOrgs"
import Title from "widgets/common/Title"
import EditableOrgName from "widgets/organization/EditableOrgName"
import NotificationSettings from "widgets/organization/NotificationSettings"
import OrgUsers from "widgets/organization/OrgUsers"
import usePermissions from "helpers/hooks/usePermissions"

function OrgSettings() {
  const { t } = useTranslation()
  const currentOrgId = useSelector(selectCurrentOrgId)
  const { getOrgById, orgsFetching } = useOrgs()
  const { isAdmin } = usePermissions()

  const currentOrg = useMemo(() => getOrgById(currentOrgId), [currentOrgId, getOrgById])

  return (
    <>
      {t("organization.ORGANIZATION_SETTINGS")}

      {!currentOrg ? (
        <></>
      ) : isAdmin ? (
        <EditableOrgName name={currentOrg.name} />
      ) : (
        <Title text={currentOrg.name} />
      )}

      {currentOrg && <NotificationSettings org={currentOrg} isFetching={orgsFetching} />}
      <OrgUsers orgId={currentOrgId} />
    </>
  )
}

export default OrgSettings
