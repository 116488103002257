import { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import CreateUserPopup from "./CreateUserPopup"

const CreateUserButton = () => {
  const { t } = useTranslation()
  const [createPopupOpen, setCreatePopupOpen] = useState(false)

  const handleCreateUserOpen = () => {
    setCreatePopupOpen(true)
  }
  const handleCreateUserClose = () => {
    setCreatePopupOpen(false)
  }

  return (
    <>
      <Tooltip title={t("enterprise.CREATE_USER")}>
        <IconButton onClick={handleCreateUserOpen} color="primary" size="small">
          <AddIcon />
        </IconButton>
      </Tooltip>
      {createPopupOpen && (
        <CreateUserPopup open={createPopupOpen} onClose={handleCreateUserClose} />
      )}
    </>
  )
}

export default CreateUserButton
