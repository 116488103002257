import { useMemo } from "react"
import { useOutletContext } from "react-router-dom"
import Grid from "@mui/material/Grid"
import fp from "lodash/fp"

import { useLastLocationsQuery } from "features/api"
import DeviceGroupDevicesTable from "widgets/device/DevicesTable/DeviceGroupDevicesTable"
import useDevices from "helpers/hooks/useDevices"
import { formatLocationData } from "helpers/formatters/dataFormatters"
import { BodyContainer } from "widgets/styled/containers"
import type { DeviceGroupType } from "types/group.types"
import { mapView } from "helpers/config/plots"
import Map from "widgets/common/Map"

function GroupInfo() {
  const { deviceGroup, deviceGroupLoading } = useOutletContext<{
    deviceGroup: DeviceGroupType
    deviceGroupLoading: boolean
  }>()
  const {
    devsWRelsByIdWStates,
    lessIdByDeviceId,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useDevices()
  const deviceList = useMemo(
    () => deviceGroup.device_list as number[],
    [deviceGroup.device_list],
  )
  const groupDevices = useMemo(() => {
    if (devsWRelsByIdWStates && lessIdByDeviceId) {
      return deviceList
        .map((deviceId) => devsWRelsByIdWStates[lessIdByDeviceId[deviceId]])
        .filter((deviceData) => deviceData !== undefined)
    } else {
      return []
    }
  }, [devsWRelsByIdWStates, lessIdByDeviceId, deviceList])

  const {
    currentData: locationCurrentData,
    isSuccess: locationsSuccess,
    isLoading: locationsLoading,
  } = useLastLocationsQuery(
    { params: { group_id: deviceGroup.id } },
    {
      skip: !deviceGroup,
    },
  )
  const flattenLocationData = useMemo(
    () => formatLocationData(locationCurrentData),
    [locationCurrentData],
  )
  const devicesFirstFetch = useMemo(
    () => devicesFetching && fp.isEmpty(devsWRelsByIdWStates),
    [devsWRelsByIdWStates, devicesFetching],
  )

  return (
    <BodyContainer disableGutters maxWidth="xl">
      <Grid container spacing={2} marginTop={1}>
        <Map
          isPending={locationsLoading}
          isSuccess={locationsSuccess}
          data={flattenLocationData}
          view={mapView}
        />
        <Grid item xs={12} sx={{ mt: "1rem" }}>
          <DeviceGroupDevicesTable
            devices={groupDevices}
            isFetching={devicesFirstFetch}
            isLoading={devicesLoading || deviceGroupLoading}
            titleKey="device_groups.GROUP_DEVICES"
            group={deviceGroup}
          />
        </Grid>
      </Grid>
    </BodyContainer>
  )
}

export default GroupInfo
